<app-headline [title]="'default.details' | translate"></app-headline>
<form [formGroup]="detailForm" class="form details-form">
  <app-input-wrapper [id]="'name'" [label]="'default.name' | translate" [control]="detailForm.controls['name']">
    <input type="text" id="name" formControlName="name"
           [placeholder]="'default.inputPlaceholder' | translate">
  </app-input-wrapper>
  <app-input-wrapper [id]="'description'" [label]="'default.description' | translate" [control]="detailForm.controls['description']">
        <textarea id="description" formControlName="description"
                  [placeholder]="'default.inputPlaceholder' | translate"></textarea>
  </app-input-wrapper>
</form>
<div class="button-wrapper mid-section">
  <app-button (click)="onSaveModule()" [label]="'default.save' | translate"
              [isDisabled]="detailForm.invalid || !detailForm.dirty || module?.readonly"></app-button>
</div>
<app-headline [title]="'modules.components' | translate">
  <app-button (click)="onShowAddComponentOverlay()"
              [isSmall]="true" [type]="ButtonType.SECONDARY"
              [isDisabled]="module?.readonly"
              [label]="'modules.newComponent' | translate"></app-button>
</app-headline>
<app-filter-option-wrapper
  [searchPlaceholder]="'modules.searchComponents' | translate"
  [filterOptions]="filterOptions"
  (searchTerm)="onSearchTermChange($event)"
  (selectedOption)="onFilterOptionChange($event)"
></app-filter-option-wrapper>
@if (isEmpty) {
  <app-empty-state-list [emptyStateText]="'modules.componentEmptyState' | translate"></app-empty-state-list>
} @else {
  <div class="table table-grid">
    <div class="table-row table-header-row">
      <div class="table-cell">{{ 'default.name' | translate }}</div>
      <div class="table-cell">{{ 'default.updatedDate' | translate }}</div>
      <div class="table-cell">{{ 'default.edit' | translate }}</div>
    </div>
    <app-table-info-row [element]="module?.name"></app-table-info-row>
    @for (component of module?.components; track component) {
      @if (isAssembly(component.type)) {
        <app-assembly-table-row [assembly]="component" [offset]="-1" [enableDelete]="!module?.readonly"></app-assembly-table-row>
      } @else {
        <app-part-table-row [part]="component" [offset]="-1" [enableDelete]="!module?.readonly"></app-part-table-row>
      }
    }
  </div>
}
