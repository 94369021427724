import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  BreadCrumbNavigationComponent
} from "../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component";
import {PageLayoutComponent} from "../../../shared/components/page-layout/page-layout.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {MatTab, MatTabGroup, MatTabLink, MatTabNav} from "@angular/material/tabs";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {MatFormField, MatInput, MatLabel, MatPrefix, MatSuffix} from "@angular/material/input";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {MatOption, MatSelect} from "@angular/material/select";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  ModuleGeneralComponent
} from "../module-general/module-general.component";
import {ActivatedRoute, CanDeactivate, RouterLink} from "@angular/router";
import {RouteConstants} from "../../../shared/constants/route-constants";
import {ModuleDetailsService} from "../../services/module-details.service";
import {
  ModuleRulesComponent
} from "../module-rules/module-rules.component";
import {map, Observable, Subject, takeUntil} from 'rxjs';
import {TabBarEnum} from '../../../shared/enums/tab-bar-enum';
import {TabBarComponent} from '../../../shared/components/tab-bar/tab-bar.component';
import {UiTabBar} from '../../../shared/classes/ui-tab-bar';
import {
  ModuleParametersComponent
} from "../module-parameters/module-parameters.component";
import {UiBreadCrumbItem} from '../../../shared/classes/ui-bread-crumb-item';
import {WsModuleResponseDto} from '@fertirob/fertirob-api';
import {AsyncPipe} from '@angular/common';
import {MatTooltip} from "@angular/material/tooltip";
import {InfoBannerComponent} from "../../../shared/components/info-banner/info-banner.component";
import {ComponentDetailsService} from '../../services/component-details.service';

@Component({
  selector: 'app-page-module-details',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    PageLayoutComponent,
    HeadlineComponent,
    MatTab,
    MatTabGroup,
    ButtonComponent,
    MatInput,
    MatFormField,
    MatIcon,
    MatPrefix,
    TranslateModule,
    MatSelect,
    MatLabel,
    MatOption,
    MatSuffix,
    ReactiveFormsModule,
    ModuleGeneralComponent,
    RouterLink,
    ModuleRulesComponent,
    MatTabNav,
    MatTabLink,
    TabBarComponent,
    ModuleParametersComponent,
    AsyncPipe,
    MatTooltip,
    InfoBannerComponent
  ],
  templateUrl: './page-module-details.component.html',
  styleUrl: './page-module-details.component.scss'
})
export class PageModuleDetailsComponent implements OnInit, OnDestroy, CanDeactivate<any> {
  @ViewChild(ModuleGeneralComponent) moduleGeneralComponent?: ModuleGeneralComponent;
  @ViewChild(ModuleRulesComponent) moduleRulesComponent?: ModuleRulesComponent;
  @ViewChild(ModuleParametersComponent) moduleParametersComponent?: ModuleParametersComponent;

  public module$: Observable<WsModuleResponseDto | undefined> = this._moduleDetailsService.getModule();
  public breadCrumbItems: UiBreadCrumbItem[] = [];
  private _notifier = new Subject<void>();

  public statesList: UiTabBar[] = [
    {label: TabBarEnum.GENERAL, link: ['../', RouteConstants.routeGeneral]},
    {label: TabBarEnum.RULES, link: ['../', RouteConstants.routeRules]},
    {label: TabBarEnum.PARAMETERS, link: ['../', RouteConstants.routeParameters]}
  ];
  public activeState?: UiTabBar;

  constructor(private _route: ActivatedRoute,
              private _translate: TranslateService,
              private _componentDetailsService: ComponentDetailsService,
              private _moduleDetailsService: ModuleDetailsService) {
  }

  ngOnInit(): void {
    this._route.params.subscribe(params => {
      if (params['idModule']) {
        this._moduleDetailsService.fetchModule(params['idModule']);
        this._componentDetailsService.resetComponent()
      }
    });
    this.module$.pipe(takeUntil(this._notifier)).subscribe(module => {
      this.breadCrumbItems = [];
      if (module) {
        this._translate.get(['modules.modules']).subscribe((translations => {
          this.breadCrumbItems.push(
            {label: translations['modules.modules'], route: ['/', RouteConstants.routeModules]},
            {label: module?.name ?? ''}
          );
        }))
      }
    })
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  get isGeneralTabActive(): boolean {
    return this.activeState?.label === TabBarEnum.GENERAL
  }

  get isRulesTabActive(): boolean {
    return this.activeState?.label === TabBarEnum.RULES
  }

  get isParametersTabActive(): boolean {
    return this.activeState?.label === TabBarEnum.PARAMETERS
  }

  onChangeActiveState(activeState: UiTabBar): void {
    this.activeState = activeState;
  }

  canDeactivate(): Observable<boolean> | boolean {
    switch (this.activeState?.label) {
      case TabBarEnum.GENERAL:
        return this.moduleGeneralComponent ? this.moduleGeneralComponent.canDeactivate() : true;
      case TabBarEnum.RULES:
        return this.moduleRulesComponent ? this.moduleRulesComponent.canDeactivate() : true;
      case TabBarEnum.PARAMETERS:
        return this.moduleParametersComponent ? this.moduleParametersComponent.canDeactivate() : true;
      default:
        return true;
    }
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
  protected readonly RouteConstants = RouteConstants;
}
