<app-overlay>
  <ng-container header>
    <app-headline [title]="'modules.addComponentOverlay.headline' | translate">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="onHideOverlay()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <form [formGroup]="addForm" class="form">
      <app-input-wrapper [id]="'name'" [label]="'default.componentType' | translate" [control]="addForm.controls['type']">
        <mat-radio-group formControlName="type">
          <mat-radio-button class="example-radio-button" [value]="WsComponentRequestDtoTypeEnum.PART">{{'default.part' | translate}}</mat-radio-button>
          <mat-radio-button class="example-radio-button" [value]="WsComponentRequestDtoTypeEnum.ASSEMBLY">{{'default.assemblyGroup' | translate}}</mat-radio-button>
        </mat-radio-group>
      </app-input-wrapper>
      <app-input-wrapper [id]="'name'" [label]="'default.name' | translate" [control]="addForm.controls['name']">
        <input type="text" id="name" formControlName="name"
               [placeholder]="'default.inputPlaceholder' | translate">
      </app-input-wrapper>
      <app-input-wrapper [id]="'description'" [label]="'default.description' | translate" [control]="addForm.controls['description']">
        <textarea id="description" formControlName="description"
                  [placeholder]="'default.inputPlaceholder' | translate"></textarea>
      </app-input-wrapper>
    </form>
  </ng-container>
  <ng-container buttons>
    <app-button (click)="onHideOverlay()"
                [isSmall]="true" [type]="ButtonType.SECONDARY"
                [label]="'default.cancel' | translate"></app-button>
    <app-button (click)="onSubmit()"
                [isDisabled]="addForm.invalid"
                [isSmall]="true" [type]="ButtonType.PRIMARY"
                [label]="'default.create' | translate"></app-button>
  </ng-container>
</app-overlay>
