import { Injectable } from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  public getErrorName(error: unknown) {
    if (error instanceof HttpErrorResponse) return error.status
    return String(error)
  }
}
