import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-empty-state-list',
  standalone: true,
  imports: [],
  templateUrl: './empty-state-list.component.html',
  styleUrl: './empty-state-list.component.scss'
})
export class EmptyStateListComponent {
  @Input() emptyStateText?: string = '';
}
