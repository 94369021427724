import {Component, Input} from '@angular/core';
import {IconEnum} from "../../enums/icon-enum";
import {TranslateModule} from "@ngx-translate/core";
import {ChipType} from "../../enums/chip-enum";

@Component({
  selector: 'app-chip',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './chip.component.html',
  styleUrl: './chip.component.scss'
})
export class ChipComponent {
  @Input() type: ChipType = ChipType.NEW_COMMENT;
  @Input() counter?: string | number;

  protected readonly IconEnum = IconEnum;
    protected readonly ChipType = ChipType;

  getTranslatedText(): string {
    switch (this.type) {
      case ChipType.COMPLETE:
        return 'default.complete';
      case ChipType.IN_COMPLETE:
        return 'default.incomplete';
      case ChipType.NEW_COMMENT:
        return 'documents.newComment';
      case ChipType.NEW_VERSION:
        return 'documents.newVersion';
      case ChipType.PERSON_COUNT:
        return 'default.people';
      default:
        return 'documents.complete';
    }
  }
}
