<app-headline [title]="'modules.parameterList' | translate">
  <app-button [isSmall]="true"
              [isDisabled]="isEmpty || isReadOnlyMode"
              [label]="'select.selectParameters' | translate"
              (click)="toggleSelectionMode(true)"
              [type]="ButtonType.SECONDARY"></app-button>
</app-headline>
<app-filter-option-wrapper
  [searchPlaceholder]="'modules.searchComponents' | translate"
  [filterOptions]="filterOptions"
  (searchTerm)="onSearchTermChange($event)"
  (selectedOption)="onFilterOptionChange($event)"
></app-filter-option-wrapper>
@if (selectionMode) {
  <app-selection-bar [selectedAmount]="checkedParameters.length"
                     (onChangeSelectionMode)="toggleSelectionMode(false)"
                     (onTriggerDelete)="deleteOverlay()"
                     (onTriggerDownload)="onDownload()"
  ></app-selection-bar>
}
@if (isEmpty) {
  <app-empty-state-list
    [emptyStateText]="'modules.modulesParametersEmptyState' | translate"></app-empty-state-list>
} @else {
  <table matSort (matSortChange)="sortData($event)" [formGroup]="parameterValueForm">
    <tbody formArrayName="parameterValues">
    <tr>
      <th>
        <div class="flex-content-wrapper">
          @if (!isReadOnlyMode && selectionMode) {
            <mat-checkbox (change)="onCheckAll($event.checked)"
                          [checked]="areAllChecked()"
                          [indeterminate]="areSomeChecked()">
            </mat-checkbox>
          }
          {{ 'default.name' | translate }}
        </div>
      </th>
      <th>{{ 'default.description' | translate }}</th>
      <th>{{ 'default.value' | translate }}</th>
      <th class="table-head-sort"
          mat-sort-header="lastModified">{{ 'default.updatedDate' | translate }}
      </th>
      <th>{{ 'default.edit' | translate }}</th>
    </tr>
      @for (param of parameters; track param.id; let index = $index) {
        <tr [formGroupName]="index">
          <td>
            <div class="flex-content-wrapper">
              @if (!isReadOnlyMode && selectionMode) {
                <mat-checkbox [checked]="isChecked(param.id)"
                              (change)="onCheckboxCheck($event.checked, param.id)">
                </mat-checkbox>
              }
              {{ param.name }}
            </div>
          </td>
          <td>{{ param.description }}</td>
          <td>
            <div class="input-wrapper">
              {{param.value ? param.value : 'default.noEntry' | translate}}
            </div>
          </td>
          <td>{{ param.createdDate | date }}</td>
          <td class="icon-cell">
            <div class="flex-content-wrapper">
              <app-icon-button [type]="ButtonType.PLAIN" [isDisabled]="isReadOnlyMode"
                               [iconName]="IconEnum.DELETE" (click)="deleteOverlay(param)"></app-icon-button>
              @if (!hideEdit) {
                <app-icon-button (click)="onEditParameter(param)" [isDisabled]="isReadOnlyMode || disableEdit"
                                 [type]="ButtonType.PLAIN"
                                 [iconName]="IconEnum.EDIT"></app-icon-button>
              }
            </div>
          </td>
        </tr>
      }
    </tbody>
  </table>
}
<!--<div class="button-wrapper bottom-of-page">
  <app-button (click)="onSave()" [isDisabled]="!parameterValueForm.dirty || parameterValueForm.invalid || isReadOnlyMode"
              [label]="'default.save' | translate"></app-button>
</div>-->
