import {Component, Input, OnInit} from '@angular/core';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {AssemblyTableRowComponent} from '../assembly-table-row/assembly-table-row.component';
import {PartTableRowComponent} from '../part-table-row/part-table-row.component';
import {DatePipe, NgClass} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {ButtonType} from '../../../shared/enums/button-enum';
import {RouteConstants} from '../../../shared/constants/route-constants';
import {
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum,
  WsModuleResponseDto,
  WsProjectResponseDto
} from '@fertirob/fertirob-api';
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {
  DeleteOverlayComponent
} from "../../../shared/components/delete-overlay/delete-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {routes} from "../../../app.routes";
import {ModuleDetailsService} from '../../services/module-details.service';

@Component({
  selector: 'app-module-table-row',
  standalone: true,
  imports: [
    AssemblyTableRowComponent,
    PartTableRowComponent,
    DatePipe,
    TranslateModule,
    RouterLink,
    IconButtonComponent,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    NgClass
  ],
  templateUrl: './module-table-row.component.html',
  styleUrl: './module-table-row.component.scss'
})
export class ModuleTableRowComponent implements OnInit {
  @Input() module?: WsModuleResponseDto;
  @Input() enableDelete?: boolean;
  @Input() hideDate?: boolean;

  //TODO:Replace with actual data
  public includedIn: WsProjectResponseDto[] = [
  /*  {
      id: '1',
      name: 'Project 1',
    },
    {
      id: '2',
      name: 'Project 2',
    }*/
  ];

  public isSelected: boolean = false;
  public hasParameters: boolean = false;

  protected readonly IconEnum = IconEnum;

  constructor(private _dialog: MatDialog,
              private _moduleDetailsService: ModuleDetailsService,
              private _translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.module) {
      this.checkForParameters(this.module.components);
    }
  }


  public selectModule(): void {
    this.isSelected = !this.isSelected;
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }

  get hasRules(): boolean {
    if (!this.module?.rules) {
      return false;
    }
    return this.module?.rules?.length > 0;
  }

  public checkForParameters(components: WsComponentResponseDto[] | undefined): void {
    components?.forEach((component) => {
      if (component.parameters && component.parameters?.length > 0) {
        this.hasParameters = true;
        return;
      }
      this.checkForParameters(component.subComponents)
    })
  }

  protected readonly ButtonType = ButtonType;
  protected readonly RouteConstants = RouteConstants;

  deleteOverlay() {
    const dialogRef = this._dialog.open(DeleteOverlayComponent);

    dialogRef.componentInstance.values = this.module?.name;
    dialogRef.componentInstance.headline = this._translate.instant('modules.delete.deleteModule');

    dialogRef.afterClosed().subscribe(result => {
      if (result && this.module?.id) {
        this._moduleDetailsService.deleteModule(this.module?.id);
      }
    })
  }

  protected readonly RouterLink = RouterLink;
}
