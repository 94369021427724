import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {FeedbackType} from '../enums/feedback-type';
import {CustomSnackBarComponent} from '../components/custom-snack-bar/custom-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private _snackBar: MatSnackBar,
              private _translate: TranslateService) {
  }

  public setFeedback(message: string, type: FeedbackType): void {
    let description = this._translate.instant(message);
    this.openSnackBar(description, type);
  }

  public setClipboardCopy(successful: boolean): void {
    let type = successful ? FeedbackType.SUCCESS : FeedbackType.PLAIN;
    let description = successful ? this._translate.instant('snackbar.copyToClipboard.success') : this._translate.instant('snackbar.copyToClipboard.error')
    this.openSnackBar(description, type);
  }

  public setErrorCodeMessage(errorCode: string | number): void {
    let description = this._translate.instant('snackbar.error.errorCode', {code: errorCode}) + this._translate.instant('snackbar.error.' + errorCode);
    this.openSnackBar(description, FeedbackType.ERROR);
  }

  private openSnackBar(description: string, type: FeedbackType): void {
    this._snackBar.openFromComponent(CustomSnackBarComponent, {
      data: {
        description: description,
        type: type
      }
    });
  }
}
