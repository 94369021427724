import {Injectable} from '@angular/core';
import {BehaviorSubject, delay, firstValueFrom, Observable} from 'rxjs';
import {MockFixturesService} from '../../shared/services/mock-fixtures.service';
import {ModulesApiService, WsModuleRequestDto, WsModuleResponseDto} from '@fertirob/fertirob-api';
import {UrlConstants} from '../../shared/constants/url-constants';
import {FeedbackType} from '../../shared/enums/feedback-type';
import {FeedbackService} from '../../shared/services/feedback.service';
import {HelperService} from '../../shared/services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleListService {

  private _pageSize: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  protected _totalCount: number = 0;
  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _moduleList: BehaviorSubject<WsModuleResponseDto[]> = new BehaviorSubject<WsModuleResponseDto[]>([]);
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sort: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _helperService: HelperService,
    private _modulesApiService: ModulesApiService,
    private _feedbackService: FeedbackService
  ) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchModuleList();
  }

  public get getTotalCount(): number {
    return this._totalCount;
  }

  public get getPageSize(): number {
    return this._pageSize.getValue();
  }

  public setPageSize = (pageSize: number): void => {
    this._pageSize.next(pageSize)
    this.fetchModuleList();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchModuleList();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchModuleList();
  }

  public getModuleList = (): Observable<WsModuleResponseDto[]> => {
    return this._moduleList.asObservable();
  }

  public fetchModuleList = async (): Promise<void> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._modulesApiService.getModules(this.getPageSize, this.getCurrentPage, 'response'));
      if (result.headers.get(UrlConstants.TotalCountHeader)) {
        this._totalCount = Number(result.headers.get(UrlConstants.TotalCountHeader))
      }
      if (result.body) {
        this._moduleList.next(result.body);
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err;
    }
  }

  public saveModule = async (saveModule: WsModuleRequestDto): Promise<string> => {
    try {
      const result = await firstValueFrom(this._modulesApiService.createOrUpdateModule(saveModule))
      this._feedbackService.setFeedback('snackbar.moduleCreate.success', FeedbackType.SUCCESS);
      await this.fetchModuleList();
      return Promise.resolve(result.id ?? '');
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }
}
