<app-page-layout [noButtons]="true">
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    <app-headline [title]="'documents.documents' | translate" [hideDivider]="true">
      <app-button [label]="'select.selectDocs' | translate"
                  (click)="toggleSelectionMode(true)"
                  [type]="ButtonType.SECONDARY"></app-button>
      <app-button [label]="'default.new' | translate"></app-button>
    </app-headline>
    @if (selectionMode) {
      <app-selection-bar [selectedAmount]="checkedDocuments.length"
                         (onChangeSelectionMode)="toggleSelectionMode(false)"
                         (onTriggerDelete)="onDelete()"
                         (onTriggerDownload)="onDownload()"
      ></app-selection-bar>
    }
    @if (!(isEmpty) && !(isLoading$ | async)) {
      <div class="main-content">
        <div class="table table-grid" cdkDropListGroup (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)">
          <div class="table-row table-header-row" [class.two-column]="showVersions || showComments"
               [class.four-column]="!showComments && !showVersions">
            <div class="table-cell">
              <div class="flex-content-wrapper">
                @if (selectionMode) {
                  <mat-checkbox (change)="onCheckAll($event.checked)"
                                [checked]="areAllChecked()"
                                [indeterminate]="areSomeChecked()">
                  </mat-checkbox>
                }
                {{ 'default.name' | translate }}
              </div>
            </div>
            @if (!showVersions && !showComments) {
              <div class="table-cell">{{ 'documents.responsibleUser' | translate }}</div>
              <div class="table-cell">{{ 'documents.uploaded' | translate }}</div>
            }
            <div class="table-cell">{{ 'default.edit' | translate }}</div>
          </div>
          @if (isDragged) {
            <input type="file" class="dropzone-files" (change)="updateDocuments($event)">
          }
          <div class="dropzone" [class.dragover]="isDragged">
            @for (document of folders; track document.id; let i = $index) {
              <div class="table-row folder-row" [class.two-column]="showVersions || showComments"
                   [class.four-column]="!showVersions && !showComments" cdkDropList
                   cdkDropListSortingDisabled
                   [cdkDropListData]="folders"
                   (cdkDropListDropped)="drop($event, document.id)"
                   (click)="showSubDocuments(document.id)">
                <div class="table-cell">
                  @if (selectionMode) {
                    <mat-checkbox (change)="onCheckboxCheck($event.checked, document.id)"
                                  [checked]="isChecked(document.id)"
                                  (click)="$event.stopPropagation()"></mat-checkbox>
                  }
                  <div class="flex-content-wrapper">
                    <i
                      class="material-icons">{{ document.isFile ? IconEnum.DOCUMENTS : IconEnum.FOLDER }}</i>
                    {{ document.name }}
                  </div>
                </div>
                @if (!showVersions && !showComments) {
                  <div class="table-cell">{{ document.responsibleUser }}</div>
                  <div class="table-cell">{{ document.updatedDate | date: 'short' }}</div>
                }
                <div class="table-cell" (click)="$event.stopPropagation()">
                  <div class="flex-content-wrapper">
                    <app-icon-button [iconName]="IconEnum.DOWNLOAD"
                                     [type]="ButtonType.PLAIN"></app-icon-button>
                    <app-icon-button [iconName]="IconEnum.MORE"
                                     [type]="ButtonType.PLAIN"
                                     [matMenuTriggerFor]=" menu"></app-icon-button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="onDelete()">
                        <mat-icon class="material-icons">{{ IconEnum.DELETE }}</mat-icon>
                        {{ 'documents.dropdown.delete' | translate }}
                      </button>
                      <button mat-menu-item>
                        <mat-icon class="material-icons">{{ IconEnum.DOWNLOAD }}</mat-icon>
                        {{ 'documents.dropdown.download' | translate }}
                      </button>
                      @if (document?.isFile) {
                        <button mat-menu-item (click)="onShowVersions()">
                          <mat-icon class="material-icons">{{ IconEnum.HISTORY }}</mat-icon>
                          {{ 'documents.dropdown.version' | translate }}
                        </button>
                        <button mat-menu-item (click)="onShowComments()">
                          <mat-icon class="material-icons">{{ IconEnum.COMMENT }}</mat-icon>
                          {{ 'documents.dropdown.comment' | translate }}
                        </button>
                        <button mat-menu-item (click)="showUploadOverlay()">
                          <mat-icon class="material-icons">{{ IconEnum.UPLOAD }}</mat-icon>
                          {{ 'documents.dropdown.upload' | translate }}
                        </button>
                      }
                    </mat-menu>
                  </div>
                </div>
              </div>
            }
            @for (document of documents; track document.id; let i = $index) {
              <div cdkDropList cdkDropListSortingDisabled [cdkDropListData]="documents"
                   (cdkDropListDropped)="drop($event, undefined)">
                <div class="table-row documents" [class.two-column]="showVersions || showComments"
                     [class.four-column]="!showVersions && !showComments" cdkDrag>
                  <div class="table-cell" cdkDragHandle>
                    @if (selectionMode) {
                      <mat-checkbox (change)="onCheckboxCheck($event.checked, document.id)"
                                    [checked]="isChecked(document.id)"></mat-checkbox>
                    }
                    <div class="flex-content-wrapper">
                      <i
                        class="material-icons">{{ document.isFile ? IconEnum.DOCUMENTS : IconEnum.FOLDER }}</i>
                      {{ document.name }}
                      <app-chip [type]="ChipType.NEW_COMMENT"></app-chip>
                    </div>
                    <!--
                    -->
                  </div>
                  @if (!showVersions && !showComments) {
                    <div class="table-cell" cdkDragHandle>{{ document.responsibleUser }}
                    </div>
                    <div class="table-cell" cdkDragHandle>{{ document.updatedDate | date: 'short' }}
                    </div>
                  }
                  <div class="table-cell" cdkDragHandle>
                    <div class="flex-content-wrapper">
                      <app-icon-button [iconName]="IconEnum.DOWNLOAD"
                                       [type]="ButtonType.PLAIN"></app-icon-button>
                      <app-icon-button [iconName]="IconEnum.MORE"
                                       [type]="ButtonType.PLAIN"
                                       [matMenuTriggerFor]=" menu"></app-icon-button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="onDelete()">
                          <mat-icon class="material-icons">{{ IconEnum.DELETE }}</mat-icon>
                          {{ 'documents.dropdown.delete' | translate }}
                        </button>
                        <button mat-menu-item>
                          <mat-icon class="material-icons">{{ IconEnum.DOWNLOAD }}</mat-icon>
                          {{ 'documents.dropdown.download' | translate }}
                        </button>
                        @if (document?.isFile) {
                          <button mat-menu-item (click)="onShowVersions()">
                            <mat-icon class="material-icons">{{ IconEnum.HISTORY }}</mat-icon>
                            {{ 'documents.dropdown.version' | translate }}
                          </button>
                          <button mat-menu-item (click)="onShowComments()">
                            <mat-icon class="material-icons">{{ IconEnum.COMMENT }}</mat-icon>
                            {{ 'documents.dropdown.comment' | translate }}
                          </button>
                          <button mat-menu-item (click)="showUploadOverlay()">
                            <mat-icon class="material-icons">{{ IconEnum.UPLOAD }}</mat-icon>
                            {{ 'documents.dropdown.upload' | translate }}
                          </button>
                        }
                      </mat-menu>
                    </div>
                  </div>
                  <div *cdkDragPreview class="dragdrop-preview">{{ document.name }}</div>
                </div>
              </div>
            }
          </div>
        </div>
        @if (showVersions) {
          <div class="version-list">
            <app-headline [title]="'default.version' | translate">
              <app-icon-button [iconName]="IconEnum.CLOSE" [type]="ButtonType.PLAIN"
                               (click)="onShowVersions()"></app-icon-button>
            </app-headline>
            <div class="version-content">
              @for (version of versions; track version) {
                <app-version [version]="version"></app-version>
              }
            </div>
          </div>
        }
        @if (showComments) {
          <div class="comment-list">
            <app-headline [title]="'default.comments' | translate">
              <app-icon-button [iconName]="IconEnum.CLOSE" [type]="ButtonType.PLAIN"
                               (click)="onShowComments()"></app-icon-button>
            </app-headline>
            <div class="comment-content">
              @for (comment of comments; let i = $index; track comment) {
                <app-comment [comment]="comment"
                             (triggerSelected)="setSelectedCommentId($event)"
                             [isSelectedComment]="selectedCommentId"></app-comment>
              }
            </div>
            <div class="input-wrapper send">
              <div class="textarea-wrapper">
               <textarea matInput class="comment-textarea" [(ngModel)]="value"
                         [placeholder]="'comments.newComment' | translate"></textarea>
                <button class="send-button" matSuffix [disabled]="!value">
                  <mat-icon class="material-icons">{{ IconEnum.SEND }}</mat-icon>
                </button>
              </div>
            </div>
          </div>
        }
      </div>
      <app-custom-paginator
        (pageChanged)="onChangePage($event)"
        [totalElements]="totalCount"
        [currentPage]="currentPage"
        [pageSize]="pageSize"
      ></app-custom-paginator>
    }

    @if (isEmpty && !(isLoading$ | async)) {
      <app-empty-state-list
        [emptyStateText]="'documents.documentsEmptyState' | translate"></app-empty-state-list>
    }
  </ng-container>
</app-page-layout>
