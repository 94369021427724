import {inject, Injectable} from '@angular/core';
import {CanDeactivateFn} from '@angular/router';
import {Observable} from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuardService {
  canDeactivate(
    component: CanComponentDeactivate): boolean {
    return component.canDeactivate && component.canDeactivate();
  }
}

export const hasUnsavedChangesGuard: CanDeactivateFn<CanComponentDeactivate> = (component: CanComponentDeactivate): Observable<boolean> | boolean => {
  return inject(CanDeactivateGuardService).canDeactivate(component);
}
