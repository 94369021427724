import {Component, Input} from '@angular/core';
import {IconButtonComponent} from '../icon-button/icon-button.component';
import {ButtonType} from '../../enums/button-enum';
import {IconEnum} from '../../enums/icon-enum';

@Component({
  selector: 'app-overlay',
  standalone: true,
  imports: [
    IconButtonComponent
  ],
  templateUrl: './overlay.component.html',
  styleUrl: './overlay.component.scss'
})
export class OverlayComponent {
  @Input() isSmall: boolean = false;
  @Input() isBig: boolean = false;

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
