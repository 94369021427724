<div class="table-row highlight-row one-column">
  <div class="table-cell">
    <app-icon-button (click)="selectModule()"
                     [iconName]="isSelected ? IconEnum.ARROW_UP : IconEnum.ARROW_DOWN"
                     [type]="ButtonType.PLAIN"></app-icon-button>
    {{ module?.name }}
    <app-chip [type]="isModuleCompleted ? ChipType.COMPLETE : ChipType.IN_COMPLETE "></app-chip>
  </div>
</div>
@if (isSelected) {
  @for (assembly of module?.components; track assembly) {
    @if (hasParameters(assembly) || isAssembly(assembly.type)) {
      <app-component-table-row-in-project [component]="assembly" [projectParameters]="projectParameters" (onFormChange)="changeModuleIsCompleted($event)" (onFormDirty)="onFormDirty($event)"></app-component-table-row-in-project>
    }
  }
}
