<div class="selection-banner">
  <div class="selection-banner-group">
    <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE"
                     (click)="toggleSelectionMode()"></app-icon-button>
    {{ selectedAmount }} {{ 'select.selected' | translate }}
  </div>
  <div class="selection-banner-group">
    <app-icon-button (click)="onDownload()"
                     [isDisabled]="noSelection"
                     [type]="ButtonType.PLAIN"
                     [iconName]="IconEnum.DOWNLOAD"></app-icon-button>
    <app-icon-button (click)="onDelete()"
                     [isDisabled]="noSelection"
                     [type]="ButtonType.PLAIN"
                     [iconName]="IconEnum.DELETE"></app-icon-button>
  </div>
</div>
