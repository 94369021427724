import { Injectable } from '@angular/core';
import {
  WsComponentRequestDtoTypeEnum,
  WsComponentResponseDtoTypeEnum
} from "@fertirob/fertirob-api";

@Injectable({
  providedIn: 'root'
})
export class ConverterService {

  constructor() { }

  convertComponentResponseEnumsToRequest(response?: WsComponentResponseDtoTypeEnum): WsComponentRequestDtoTypeEnum {
    switch (response) {
      case WsComponentResponseDtoTypeEnum.ASSEMBLY: return WsComponentRequestDtoTypeEnum.ASSEMBLY;
      default: return WsComponentRequestDtoTypeEnum.PART;
    }
  }
}
