import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent} from '../button/button.component';
import {UiComment} from '../../classes/ui-comment';
import {DatePipe} from '@angular/common';
import {MatFormField, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {IconEnum} from '../../enums/icon-enum';
import {TranslateModule} from '@ngx-translate/core';
import {MatInput} from '@angular/material/input';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatIconButton} from '@angular/material/button';
import {Comment} from "@angular/compiler";

@Component({
  selector: 'app-comment',
  standalone: true,
  imports: [
    ButtonComponent,
    DatePipe,
    MatFormField,
    MatIcon,
    TranslateModule,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatIconButton,
    MatSuffix
  ],
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.scss'
})
export class CommentComponent {
  @Input() comment: UiComment | undefined = undefined;
  @Input() isSubComment: boolean = false;
  @Input() showSubComments?: boolean = false;
  @Input() isSelectedComment: string = '';
  @Output() triggerSelected: EventEmitter<string> = new EventEmitter<string>();
  public writeComment: boolean = false;


  value = undefined;

  public toggleShowComments(): void {
    this.showSubComments = !this.showSubComments;
  }

  public toggleWriteComment(commentId: string): void {
    this.writeComment = !this.writeComment;
    this.triggerSelected.emit(commentId);
  }

  protected readonly IconEnum = IconEnum;
}
