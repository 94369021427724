import {Component, Input} from '@angular/core';
import {UiBreadCrumbItem} from '../../classes/ui-bread-crumb-item';
import {TranslateModule} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-bread-crumb-navigation',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink
  ],
  templateUrl: './bread-crumb-navigation.component.html',
  styleUrl: './bread-crumb-navigation.component.scss'
})
export class BreadCrumbNavigationComponent {
  @Input() items: UiBreadCrumbItem[] = [];
  public shownElements: number = 4;
}
