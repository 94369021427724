import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {ModulesApiService, WsModuleRequestDto, WsModuleResponseDto} from '@fertirob/fertirob-api';
import {HelperService} from '../../shared/services/helper.service';
import {FeedbackService} from '../../shared/services/feedback.service';
import {FeedbackType} from '../../shared/enums/feedback-type';
import {ModuleListService} from './module-list.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleDetailsService {

  private _module: BehaviorSubject<WsModuleResponseDto | undefined> = new BehaviorSubject<WsModuleResponseDto | undefined>(undefined);

  constructor(
    private _helperService: HelperService,
    private _feedbackService: FeedbackService,
    private _moduleListService: ModuleListService,
    private _modulesApiService: ModulesApiService
  ) {
  }

  public getModule = (): Observable<WsModuleResponseDto | undefined> => {
    return this._module.asObservable();
  }

  public fetchModule = async (id?: string): Promise<void> => {
    if (id === this._module.getValue()?.id) {
      return
    }
    if (!id) {
      id = this._module.getValue()?.id??'';
    }
    this._module.next(undefined);
    try {
      const result = await firstValueFrom(this._modulesApiService.getModule(id))

      if (result) {
        this._module.next(result)
      }
    } catch (err) {
      throw err
    }
  }

  public updateModule = async (updateModule: WsModuleRequestDto): Promise<string> => {
    try {
      const result = await firstValueFrom(this._modulesApiService.createOrUpdateModule(updateModule))
      this._feedbackService.setFeedback('snackbar.moduleUpdate.success', FeedbackType.SUCCESS);
      await this.fetchModule();
      return Promise.resolve(result.id ?? '');
    } catch (err: any) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err.status));
      throw err;
    }
  }

  public deleteModule = async (id: string): Promise<void> => {
    try {
      const result = await firstValueFrom(this._modulesApiService.deleteModule(id))
      this._feedbackService.setFeedback('snackbar.moduleDelete.success', FeedbackType.SUCCESS);
      await this._moduleListService.fetchModuleList();
      return Promise.resolve(result);
    } catch (err: any) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err.status));
      throw err;
    }
  }
}
