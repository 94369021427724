import {Component, ElementRef, HostListener, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgIf} from "@angular/common";
import {PdfViewerComponent} from "../pdf-viewer/pdf-viewer.component";
import {IconButtonComponent} from "../icon-button/icon-button.component";
import {IconEnum} from "../../enums/icon-enum";
import {ButtonComponent} from "../button/button.component";
import {ButtonType} from "../../enums/button-enum";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-drag-and-drop',
  standalone: true,
  imports: [
    NgIf,
    PdfViewerComponent,
    IconButtonComponent,
    ButtonComponent,
    TranslateModule
  ],
  templateUrl: './drag-and-drop.component.html',
  styleUrl: './drag-and-drop.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DragAndDropComponent,
      multi: true
    }
  ]

})
export class DragAndDropComponent implements ControlValueAccessor {
  acceptedFileTypes: string[] = ['.pdf','.jpg', '.jpeg', '.png', '.webp'];

  @Input() maxFileSize: number = 10240;
  @Input() disableDelete?: boolean = false;
  @Input() isDisabled?: boolean = false;

  imagePreview: string | ArrayBuffer | null = null
  imageUrlFromServer?: string;
  private _change: Function = () => { };
  private _touched: Function = () => { };
  @Input() file?: File | null = null;

  constructor(private _host: ElementRef<HTMLInputElement>) {
  }

  @HostListener('change', ['$event.target.files'])
  emitFiles(event: FileList) {
    const file = event && event.item(0);
    this._change(file);
    this._touched(true);
    this.file = file;
    this.createPreview(file);
  }

  @HostListener('cancel')
  touchedInput() {
    this._touched(true)
  }

  writeValue(value: string | File | null) {
    if (value) {
      if (value instanceof File) {
        this.file = value;
        this.createPreview(value);
      } else {
        this.imageUrlFromServer = value
      }
    }
  }

  registerOnChange(fn: Function) {
    this._change = fn;
  }

  registerOnTouched(fn: Function) {
    this._touched = fn;
  }


  private createPreview(file: File | null): void {
    if (!file) {
      this.imagePreview = null
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagePreview = reader.result
      };
    }
  }


  protected readonly IconEnum = IconEnum;

  deletePdfFileInput() {
    this.file = null
  }

  protected readonly ButtonType = ButtonType;

  openImgInNewTab() {
    if (this.file) {
      const blob = new Blob([this.file], {type: this.file.type});
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    }
  }
}
