import { Component } from '@angular/core';
import {MatCheckbox} from '@angular/material/checkbox';

@Component({
  selector: 'app-checkbox-rows',
  standalone: true,
    imports: [
        MatCheckbox
    ],
  templateUrl: './checkbox-rows.component.html',
  styleUrl: './checkbox-rows.component.scss'
})
export class CheckboxRowsComponent {

}
