import {ElementRef, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableHelperService {

  constructor() {
  }

  public setTableStyling(offsetElement: ElementRef | undefined, row: ElementRef | undefined, newOffset: number): void {
    if (offsetElement) {
      if (newOffset <= 0) {
        offsetElement.nativeElement.style.marginLeft = '-8px';
      } else {
        offsetElement.nativeElement.style.width = (newOffset * 24) + ((newOffset - 1) * 8) + 'px';
      }
      if (row) {
        row.nativeElement.style.opacity = 1;
      }
    }
  }
}
