<app-overlay [isBig]="true">
  <ng-container header>
    <app-headline [title]="'projects.componentDetailsOverlay.headline' | translate: {component: component?.name} ">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="onHideOverlay()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <div class="details-content-wrapper">
      <div class="table table-grid">
        @if (isAssembly(component?.type)) {
          <app-table-info-row [element]="component?.name"></app-table-info-row>
          @for (component of component?.subComponents; track component) {
            @if (isAssembly(component?.type)) {
              <app-assembly-table-row [assembly]="component" [offset]="-1" [hideDate]="true" [disableRouterLink]="true"></app-assembly-table-row>
            } @else {
              <app-part-table-row [part]="component" [offset]="-1" [hideDate]="true" [disableRouterLink]="true"></app-part-table-row>
            }
          }
        } @else {
          <app-empty-state-list [emptyStateText]="'projects.componentDetailsOverlay.emptyState' | translate"></app-empty-state-list>
        }
      </div>
      <div class="preview">
        <app-headline [title]="'default.technicalDrawings' | translate "></app-headline>
        <app-drag-and-drop [disableDelete]="true"></app-drag-and-drop>
      </div>
    </div>
  </ng-container>
</app-overlay>
