<app-overlay>
  <ng-container header>
    <app-headline [title]="'documents.trash.storageTime' | translate">
      <app-icon-button [iconName]="IconEnum.CLOSE" [type]="ButtonType.PLAIN" (click)="onHideOverlay()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <p class="current-span-set">{{ 'documents.trash.currentTimeSpan' | translate: {days: storageTime} }}</p>
    <form [formGroup]="storageTimeForm">
      <app-input-wrapper [id]="'days'" [label]="'documents.trash.storageTime' | translate" [control]="storageTimeForm.controls['days']">
        <mat-form-field class="full-width">
          <mat-select formControlName="days" id="days">
            @for (option of timeOptions; track option) {
              <mat-option [value]="option">{{ option }} {{ 'documents.trash.days' | translate }}</mat-option>
            }
          </mat-select>
          <mat-icon matSuffix class="material-icons">{{ IconEnum.ARROW_DOWN }}</mat-icon>
        </mat-form-field>
      </app-input-wrapper>
    </form>
  </ng-container>
  <ng-container buttons>
    <app-button [label]="'default.cancel' | translate" [type]="ButtonType.SECONDARY" [isSmall]="true" (click)="onHideOverlay()"></app-button>
    <app-button [label]="'default.save' | translate" [isSmall]="true" (click)="onSubmit()"></app-button>
  </ng-container>
</app-overlay>
