<app-overlay [isSmall]="true">
  <ng-container header>
    <app-headline [title]="headline">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE"
                       (click)="cancel()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <p>{{ 'default.deleteText' | translate }}</p>
    @if (description) {
      <p>{{ description }}</p>
    }
    <br>
    <p>{{ 'default.selected' | translate }}: <b>{{ values }}</b></p>
  </ng-container>
  <ng-container buttons>
    <app-button [type]="ButtonType.SECONDARY" [label]="'default.cancel' | translate"
                (click)="cancel()" [isSmall]="true"></app-button>
    <app-button [type]="ButtonType.PRIMARY" [label]="'default.delete' | translate"
                (click)="delete()" [isSmall]="true"></app-button>
  </ng-container>
</app-overlay>
