import {Component, Input} from '@angular/core';
import { UiVersions} from "../../classes/ui-versions";
import {DatePipe} from "@angular/common";
import {IconEnum} from "../../enums/icon-enum";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-version',
  standalone: true,
  imports: [
    DatePipe,
    TranslateModule
  ],
  templateUrl: './version.component.html',
  styleUrl: './version.component.scss'
})
export class VersionComponent {
  @Input() version: UiVersions | undefined = undefined;
  @Input() changeLogMsg?: string = "";


  protected readonly IconEnum = IconEnum;
}
