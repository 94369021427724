import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {MockFixturesService} from '../../shared/services/mock-fixtures.service';
import {ProjectsApiService, WsProjectResponseDto} from '@fertirob/fertirob-api';
import {UrlConstants} from '../../shared/constants/url-constants';
import {FeedbackType} from '../../shared/enums/feedback-type';
import {FeedbackService} from '../../shared/services/feedback.service';
import {HelperService} from '../../shared/services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectListService {

  protected _pageSize = 10;
  protected _totalCount: any;
  private _projectList: BehaviorSubject<WsProjectResponseDto[]> = new BehaviorSubject<WsProjectResponseDto[]>([]);
  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sort: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _helperService: HelperService,
    private _projectApiService: ProjectsApiService,
    private _mockFixturesService: MockFixturesService,
    private _feedbackService: FeedbackService
  ) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public get getTotalCount(): any {
    return this._totalCount;
  }

  public get getPageSize(): any {
    return this._pageSize;
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchProjectList();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchProjectList();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchProjectList();
  }

  public getProjectList = (): Observable<WsProjectResponseDto[]> => {
    return this._projectList.asObservable();
  }

  public fetchProjectList = async (): Promise<void> => {
    /*this._loading.next(true);
    try {
      const result = await firstValueFrom(this._projectApiService.getProjects(this.getPageSize, this.getCurrentPage, 'response'));
      if (result.headers.get(UrlConstants.TotalCountHeader)) {
        this._totalCount = result.headers.get(UrlConstants.TotalCountHeader)
      }
      if (result.body) {
        this._projectList.next(result.body);
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err;
    }*/

    //TODO Remove mock data
    this._projectList.next(this._mockFixturesService.mockProjectList());
  }

  public saveProject = async (saveProject: any): Promise<string> => {
    try {
      //TODO Call Service
      const result = {id: ''}
      this._feedbackService.setFeedback('snackbar.moduleCreate.success', FeedbackType.SUCCESS);
      await this.fetchProjectList();
      return Promise.resolve(result.id ?? '');
    } catch (err) {
      this._feedbackService.setErrorCodeMessage(this._helperService.getErrorName(err));
      throw err;
    }
  }
}
