import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {MockFixturesService} from '../../shared/services/mock-fixtures.service';
import {
  ParametersApiService,
  WsParameterRequestDto,
  WsParameterResponseDto
} from '@fertirob/fertirob-api';
import {UrlConstants} from '../../shared/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class ParameterListService {

  protected _pageSize = 10;
  protected _totalCount: any;
  private _parametersList: BehaviorSubject<WsParameterResponseDto[] | undefined> = new BehaviorSubject<WsParameterResponseDto[] | undefined>(undefined);
  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _moduleId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sort: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _parametersApiService: ParametersApiService) {
  }

  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }

  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public get getTotalCount(): any {
    return this._totalCount;
  }

  public get getPageSize(): any {
    return this._pageSize;
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchParametersList();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchParametersList();
  }

  public get getModuleId(): string {
    return this._moduleId.getValue();
  }

  public setModuleId = (moduleId: string): void => {
    this._moduleId.next(moduleId)
    this.fetchParametersList();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchParametersList();
  }

  public getParametersList = (): Observable<WsParameterResponseDto[] | undefined> => {
    return this._parametersList.asObservable();
  }

  private fetchParametersList = async (): Promise<void> => {
    this._loading.next(true);
    try {
      const result = await firstValueFrom(this._parametersApiService.getParameters(this.getPageSize, this.getCurrentPage, this.getModuleId, undefined, 'response'));
      if (result.headers.get(UrlConstants.TotalCountHeader)) {
        this._totalCount = Number(result.headers.get(UrlConstants.TotalCountHeader))
      }
      if (result.body) {
        this._parametersList.next(result.body);
      }
      this._loading.next(false);
    } catch (err) {
      this._loading.next(false);
      throw err;
    }
  }
}
