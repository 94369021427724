<app-overlay>
  <ng-container header>
    <app-headline [title]="'leavePageOverlay.title' | translate">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="onHideOverlay()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <p>{{ 'leavePageOverlay.description' | translate }}</p>
  </ng-container>
  <ng-container buttons>
    <app-button [isSmall]="true" [type]="ButtonType.SECONDARY" (click)="onLeavePage()" [label]="'leavePageOverlay.doNotSave' | translate"></app-button>
    <app-button [isSmall]="true" [type]="ButtonType.PRIMARY" (click)="onHideOverlay()" [label]="'leavePageOverlay.stayOnPage' | translate"></app-button>
  </ng-container>
</app-overlay>

