import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconEnum} from '../../enums/icon-enum';
import {MatFormField, MatPrefix, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-filter-option-wrapper',
  standalone: true,
  imports: [
    MatFormField,
    MatIcon,
    MatInput,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSuffix,
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './filter-option-wrapper.component.html',
  styleUrl: './filter-option-wrapper.component.scss'
})
export class FilterOptionWrapperComponent implements OnInit {
  @Input() searchPlaceholder: string = 'modules.searchComponents';
  @Input() filterOptions: string[] = [];

  @Output() searchTerm: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedOption: EventEmitter<string> = new EventEmitter<string>();

  public filterForm: FormGroup;

  protected readonly IconEnum = IconEnum;

  constructor(private _fb: FormBuilder) {
    this.filterForm = this._fb.group({
      searchTerm: [''],
      selectedOption: ['']
    })
  }

  ngOnInit(): void {
    this.setUpForm();
  }

  public setUpForm(): void {
    if (this.filterOptions.length > 0) {
      this.filterForm.patchValue({
        selectedOption: this.filterOptions[0]
      })
    }
    this.onFormChange();
  }

  public onFormChange() {
    this.filterForm.valueChanges.subscribe(val => {
      this.searchTerm.emit(val.searchTerm);
      this.selectedOption.emit(val.selectedOption);
    });
  }
}
