export class RouteConstants {

  static readonly routeBackOneLevel = '..';
  static readonly routeBackTwoLevel = '../..';
  static readonly routeNoId = '-';

  static readonly routeDashboard = 'dashboard';
  static readonly routeModules = 'modules';
  static readonly routeProjects = 'projects';
  static readonly routeOrganisation = 'organisation';
  static readonly routeDocuments = 'documents';
  static readonly routeTrash = 'trash';
  static readonly routeGeneral = 'general'
  static readonly routeRules = 'rules'
  static readonly routeParameters = 'parameters'
  static readonly routeInputs = 'inputs'
  static readonly routeAdd = 'add'

  static readonly routeAuthorizationError = '403';

  static readonly routeId = ':id'
  static readonly routeIdModule = ':idModule'
  static readonly routeIdComponent = ':idComponent'
}
