<div class="table-row four-column">
  <div class="table-cell" [routerLink]="[project?.id]">
    {{ project?.name }}
  </div>
  <div class="table-cell" [routerLink]="[project?.id]">
    <app-chip [type]="isProjectCompleted? ChipType.COMPLETE : ChipType.IN_COMPLETE"></app-chip>
  </div>
  <div class="table-cell" [routerLink]="[project?.id]">{{ project?.updatedDate | date: 'short' }}</div>
  <div class="table-cell">
    <div class="flex-content-wrapper">
      <app-icon-button [iconName]="IconEnum.DOWNLOAD" [type]="ButtonType.PLAIN"></app-icon-button>
      <app-icon-button [iconName]="IconEnum.DELETE" [type]="ButtonType.PLAIN" (click)="deleteOverlay(project)"></app-icon-button>
    </div>
  </div>
</div>
