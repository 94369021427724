@if (!isReadOnlyMode) {
  <app-headline [title]="(parameterIdToEdit ? 'modules.editParameters' : 'modules.addNewParameters') | translate"></app-headline>
  <form class="horizontal-form with-labels" [formGroup]="parameterForm">
    <app-input-wrapper [id]="'name'" [label]="'default.name' | translate"
                       [control]="parameterForm.controls['name']">
      <input type="text" id="name" formControlName="name"
             [placeholder]="'default.inputPlaceholder' | translate">
    </app-input-wrapper>
    <app-input-wrapper [id]="'description'" [label]="'default.description' | translate"
                       [control]="parameterForm.controls['description']">
      <input type="text" id="description" formControlName="description"
             [placeholder]="'default.inputPlaceholder' | translate">
    </app-input-wrapper>
    <app-input-wrapper [id]="'value'" [label]="'default.value' | translate"
                       [control]="parameterForm.controls['value']">
      <input type="number" id="value" formControlName="value"
             [placeholder]="'default.optional' | translate">
    </app-input-wrapper>
    @if (parameterForm.dirty || parameterIdToEdit) {
      <app-button (click)="onCancel()" [type]="ButtonType.SECONDARY"
                  [label]="'default.cancel' | translate"></app-button>

    }
    <app-button (click)="onAddParameter()"
                [type]="ButtonType.PRIMARY"
                [label]="(parameterIdToEdit ? 'default.save' : 'default.create') | translate"
                [isDisabled]="disableAddParameterButton"></app-button>
  </form>
}
<app-parameter-list [isReadOnlyMode]="isReadOnlyMode"
                    [parametersInput]="component?.parameters"
                    [disableEdit]="parameterIdToEdit != undefined"
                    [component]="component" (onSortChange)="sortData($event)"
                    (editParameter)="onEditParameter($event)"></app-parameter-list>

