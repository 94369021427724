import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {MockFixturesService} from "../../shared/services/mock-fixtures.service";
import {UiComment} from "../../shared/classes/ui-comment";

@Injectable({
  providedIn: 'root'
})
export class CommentListService {

  protected _pageSize = 10;
  protected _totalCount: any;
  private _comments: BehaviorSubject<UiComment[] | undefined>;
  private _currentPage: BehaviorSubject<number>;
  private _searchTerm: BehaviorSubject<string>;
  private _sort: BehaviorSubject<string[]>;

  constructor(private _mockFixturesService: MockFixturesService) {
    this._comments = new BehaviorSubject<UiComment[] | undefined>(undefined);
    this._currentPage = new BehaviorSubject<number>(0);
    this._searchTerm = new BehaviorSubject<string>('');
    this._sort = new BehaviorSubject<string[]>([]);
  }

  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public get getTotalCount(): any {
    return this._totalCount;
  }

  public get getPageSize(): any {
    return this._pageSize;
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchComments();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchComments();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchComments();
  }

  public getComments = (serialNumber?: string): Observable<UiComment[] | undefined> => {
    this.fetchComments();
    return this._comments.asObservable();
  }

  private fetchComments = (): void => {
    //TODO Call Service
    this._comments.next(this._mockFixturesService.mockComments());
  }
}
