import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {MatTabLink, MatTabNav} from '@angular/material/tabs';
import {TranslateModule} from '@ngx-translate/core';
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from '@angular/router';
import {UiTabBar} from '../../classes/ui-tab-bar';

@Component({
  selector: 'app-tab-bar',
  standalone: true,
  imports: [
    MatTabNav,
    MatTabLink,
    TranslateModule,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './tab-bar.component.html',
  styleUrl: './tab-bar.component.scss'
})
export class TabBarComponent implements OnInit {
  activeState?: UiTabBar;

  @Input() states: UiTabBar[] = [];
  @Output() activeStateChange = new EventEmitter<UiTabBar>();

  constructor(private _router: Router,
              private _route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._route.url.subscribe(
      (url) => {
        let activeState: UiTabBar = this.states[0]
        const urlPathArray = url.map(e => e.path)

        if (urlPathArray.length > 0) {
          this.states.forEach(s => {
            if (s.link.includes(urlPathArray[0])) {
              activeState = s
              return;
            }
          })
        }

        this.changeActiveState(activeState);
      }
    );
  }

  getStateTranslation(state: string): string {
    return 'navigation.' + state.toLowerCase();
  }

  private changeActiveState(activeState: UiTabBar): void {
    const state = activeState ? (this.states?.includes(activeState) ? activeState : (this.states ? this.states[0] : undefined)) : undefined;
    this.activeState = state;
    this.activeStateChange.emit(state);
  }

}
