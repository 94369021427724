<app-overlay>
  <ng-container header>
    <app-headline [title]="'projects.createProjectOverlay.headline' | translate">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="onHideOverlay()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <form [formGroup]="addForm" class="form">
      <app-input-wrapper [id]="'name'" [label]="'default.name' | translate" [control]="addForm.controls['name']">
        <input type="text" id="name" formControlName="name"
               [placeholder]="'default.inputPlaceholder' | translate">
      </app-input-wrapper>
      <app-input-wrapper [id]="'description'" [label]="'default.description' | translate" [control]="addForm.controls['description']">
        <textarea id="description" formControlName="description"
                  [placeholder]="'default.inputPlaceholder' | translate"></textarea>
      </app-input-wrapper>
      <app-headline [title]="'modules.modules' | translate"></app-headline>
      @if (isEmpty | async) {
        <app-empty-state-list [emptyStateText]="'projects.moduleEmptyState' | translate"></app-empty-state-list>
      } @else {
        <mat-form-field class="search-input">
          <input matInput [placeholder]="'modules.searchComponents' | translate">
          <mat-icon matPrefix class="material-icons">{{ IconEnum.SEARCH }}</mat-icon>
        </mat-form-field>
        <div [class.checkbox-wrapper]="moreThanOnePage">
          <app-checkbox-rows>
            @for (module of (modules$ | async); track module.id) {
              <mat-checkbox [value]="module.id??''" [checked]="isInList(module.id)" (change)="onCheckChange($event)">
                {{ module.name }}
              </mat-checkbox>
            }
          </app-checkbox-rows>
        </div>
        <app-custom-paginator
          (pageChanged)="onChangePage($event)"
          [totalElements]="totalCount"
          [currentPage]="currentPage"
          [pageSize]="pageSize"
        ></app-custom-paginator>
      }
    </form>
  </ng-container>
  <ng-container buttons>
    <app-button (click)="onHideOverlay()"
                [isSmall]="true" [type]="ButtonType.SECONDARY"
                [label]="'default.cancel' | translate"></app-button>
    <app-button (click)="onSubmit()"
                [isDisabled]="addForm.invalid"
                [isSmall]="true" [type]="ButtonType.PRIMARY"
                [label]="'default.create' | translate"></app-button>
  </ng-container>
</app-overlay>
