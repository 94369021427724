<app-page-layout [noButtons]="true">
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    @if (module$ | async; as module) {
      <app-headline [hideDivider]="true" [title]="module?.name"></app-headline>
      @if (module.readonly) {
        <app-info-banner [message]="'modules.lockedModule' | translate"></app-info-banner>
      }
      <app-tab-bar [states]="statesList" (activeStateChange)="onChangeActiveState($event)"></app-tab-bar>
      @if (isGeneralTabActive) {
        <app-module-general [module]="module"></app-module-general>
      } @else if (isRulesTabActive) {
        <app-module-rules [module]="module"></app-module-rules>
      } @else if (isParametersTabActive) {
        <app-module-parameters [module]="module"></app-module-parameters>
      }
    }
  </ng-container>
</app-page-layout>
