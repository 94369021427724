import {Component, Input, OnInit} from '@angular/core';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {ButtonType} from '../../../shared/enums/button-enum';
import {TranslateModule} from '@ngx-translate/core';
import {ModuleTableRowInProjectComponent} from '../module-table-row-in-project/module-table-row-in-project.component';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {ExportProjectOverlayComponent} from '../export-project-overlay/export-project-overlay.component';
import {MatDialog} from '@angular/material/dialog';
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {WsProjectResponseDto} from '@fertirob/fertirob-api';
import {map, Observable} from 'rxjs';
import {LeavePageOverlayComponent} from '../../../shared/components/leave-page-overlay/leave-page-overlay.component';

@Component({
  selector: 'app-project-inputs',
  standalone: true,
  imports: [
    TranslateModule,
    ModuleTableRowInProjectComponent,
    ButtonComponent,
    EmptyStateListComponent
  ],
  templateUrl: './project-inputs.component.html',
  styleUrl: './project-inputs.component.scss'
})
export class ProjectInputsComponent implements OnInit {
  @Input() project?: WsProjectResponseDto;
  public inputChange: boolean = false;

  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;

  constructor(
    private _dialog: MatDialog
  ) {
  }

  ngOnInit() {
  }

  public onExportProject(): void {
    const dialogRef = this._dialog.open(ExportProjectOverlayComponent);
    dialogRef.componentInstance.project = this.project;
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });
  }

  get isEmpty(): boolean {
    return !this.project?.modules || this.project?.modules.length === 0;
  }

  public onInputChange(event: any): void {
    this.inputChange = event;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.inputChange) {
      const dialogRef = this._dialog.open(LeavePageOverlayComponent);
      return dialogRef.afterClosed().pipe(map(result => result === true));
    }
    return true;
  }
}
