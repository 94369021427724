import {Component, OnInit} from '@angular/core';
import {RouteConstants} from "../../../shared/constants/route-constants";
import {Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-page-error',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './page-error.component.html',
  styleUrl: './page-error.component.scss'
})
export class PageErrorComponent implements OnInit {

  protected readonly RoutConstants = RouteConstants;
  public isAuthorizationError: boolean = false;

  constructor(private _router: Router) {
  }

  ngOnInit(): void {
    this.isAuthorizationError = this._router.url.includes(RouteConstants.routeAuthorizationError);
  }

}
