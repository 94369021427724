import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {DatePipe} from '@angular/common';
import {RouterLink} from '@angular/router';
import {TableHelperService} from '../../../shared/services/table-helper.service';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {RouteConstants} from "../../../shared/constants/route-constants";
import {WsComponentResponseDto, WsParameterResponseDto} from '@fertirob/fertirob-api';
import {MatDialog} from "@angular/material/dialog";
import {
  DeleteOverlayComponent
} from "../../../shared/components/delete-overlay/delete-overlay.component";
import {TranslateService} from "@ngx-translate/core";
import {ComponentDetailsService} from "../../services/component-details.service";

@Component({
  selector: 'app-part-table-row',
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    IconButtonComponent
  ],
  templateUrl: './part-table-row.component.html',
  styleUrl: './part-table-row.component.scss'
})
export class PartTableRowComponent implements OnInit {
  @Input() part?: WsComponentResponseDto;
  @Input() enableDelete?: boolean;
  @Input() hideDate?: boolean;
  @Input() disableRouterLink?: boolean;

  @Input() set offset(val: number) {
    this.newOffset = val + 1;
  }

  @ViewChild('offsetElement') offsetElement?: ElementRef;
  @ViewChild('row') row?: ElementRef;

  constructor(private _tableHelperService: TableHelperService, private _dialog: MatDialog, private _translate: TranslateService, private _componentDetailService: ComponentDetailsService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this._tableHelperService.setTableStyling(this.offsetElement, this.row, this.newOffset);
    }, 10);
  }

  public deleteOverlay(value: WsParameterResponseDto | undefined) {
    const dialogRef = this._dialog.open(DeleteOverlayComponent)
    dialogRef.componentInstance.values = value!.name;
    dialogRef.componentInstance.headline = this._translate.instant('modules.delete.deletePart');

    return dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._componentDetailService.deleteComponent(this.part?.id!)
      }
    })
  }

  public newOffset: number = 0;
  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;
  protected readonly RouteConstants = RouteConstants;
}
