<app-overlay>
  <ng-container header>
    <app-headline [title]="'projects.exportProjectOverlay.headline' | translate: {project: project?.name}">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="onHideOverlay()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <form [formGroup]="addForm" class="form">
      <app-input-wrapper [id]="'name'" [label]="'projects.exportProjectOverlay.file' | translate" [control]="addForm.controls['name']">
        <input type="text" id="name" formControlName="name"
               [placeholder]="'default.inputPlaceholder' | translate">
      </app-input-wrapper>
      <div class="input-wrapper">
        <label for="format">{{'projects.exportProjectOverlay.fileFormat' | translate}}</label>
        <mat-form-field>
          <mat-select formControlName="format" id="format">
            @for (format of formats; track format) {
              <mat-option [value]="format">{{ format }}</mat-option>
            }
          </mat-select>
          <mat-icon matSuffix class="material-icons">{{ IconEnum.ARROW_DOWN }}</mat-icon>
        </mat-form-field>
      </div>
    </form>
  </ng-container>
  <ng-container buttons>
    <app-button (click)="onHideOverlay()"
                [isSmall]="true" [type]="ButtonType.SECONDARY"
                [label]="'default.cancel' | translate"></app-button>
    <app-button (click)="onSubmit()"
                [isDisabled]="addForm.invalid"
                [isSmall]="true" [type]="ButtonType.PRIMARY"
                [label]="'default.export' | translate"></app-button>
  </ng-container>
</app-overlay>
