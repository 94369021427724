import {Component} from '@angular/core';
import {OverlayComponent} from "../../../shared/components/overlay/overlay.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {
  InputWrapperComponent
} from "../../../shared/components/input/input-wrapper/input-wrapper.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {FeedbackService} from "../../../shared/services/feedback.service";
import {FeedbackType} from "../../../shared/enums/feedback-type";

@Component({
  selector: 'app-upload-new-version-overlay',
  standalone: true,
  imports: [
    OverlayComponent,
    HeadlineComponent,
    TranslateModule,
    IconButtonComponent,
    ButtonComponent,
    InputWrapperComponent,
    ReactiveFormsModule
  ],
  templateUrl: './upload-new-version-overlay.component.html',
  styleUrl: './upload-new-version-overlay.component.scss'
})
export class UploadNewVersionOverlayComponent {
  public uploadForm: FormGroup;
  selectedFiles: File[] = [];


  constructor(private _dialogRef: MatDialogRef<UploadNewVersionOverlayComponent>, private _fb: FormBuilder, private _translate: TranslateService, private _feedbackService: FeedbackService) {
    this.uploadForm = this._fb.group({
      changes: ['', Validators.required]
    })
  }

  onFileSelected(event: any) {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files[i]);
    }
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  onHideOverlay() {
    this._dialogRef.close(false)
    this._feedbackService.setFeedback('documents.upload.successful', FeedbackType.SUCCESS);
  }

  removeFile(file: File) {
    const index = this.selectedFiles.indexOf(file);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }
}
