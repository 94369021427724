import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {PdfJsViewerComponent, PdfJsViewerModule} from 'ng2-pdfjs-viewer';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  standalone: true,
  imports: [
    PdfJsViewerModule
  ],
  styleUrl: './pdf-viewer.component.scss'
})
export class PdfViewerComponent {

@Input() set pdfFileInput(val: File | string | Blob | Uint8Array | undefined | null) {
    if (val instanceof File) {
      this.pdfFile = val;
      this.pdfJsViewerComponent?.refresh();
    }
  }

  @ViewChild(PdfJsViewerComponent) public pdfJsViewerComponent: PdfJsViewerComponent | undefined;

  public canvas: any;
  public page = 0;
  public note = '';
  public zoom: any;

  public pdfFile: string | Blob | Uint8Array = '';

  constructor(@Inject(DOCUMENT) document: Document) {
  }

  public pdfLoaded(event: any) {
    const pdfViewer = this.pdfJsViewerComponent?.PDFViewerApplication.pdfViewer;
    const iFrameDocument = document.getElementsByTagName('iframe')[0].contentDocument;
    const viewerContainer = iFrameDocument?.getElementById('viewerContainer');
    viewerContainer?.addEventListener('click', (evt) => {
      const ost = this.calcPageOffset(pdfViewer.currentPageNumber, iFrameDocument);
      const clickOnCanvasX = evt.pageX - ost.left;
      const clickOnCanvasY = evt.pageY - ost.top;
      const viewport = pdfViewer._pages[pdfViewer.currentPageNumber - 1].viewport;
      const x_y = viewport.convertToPdfPoint(clickOnCanvasX, clickOnCanvasY);
    });
  }

  public calcPageOffset(pageNumber: number, innerDoc: any): any {
    const pg = innerDoc.getElementById("page" + pageNumber);
    const bodyElt = innerDoc?.getElementsByTagName('body')[0];
    const rect = pg?.getBoundingClientRect();
    if (rect) {
      return {
        top: rect.top + bodyElt.scrollTop,
        left: rect.left + bodyElt.scrollLeft
      }
    }
  }
}
