<app-page-layout>
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    <app-headline [title]="'documents.projectList' | translate" [hideDivider]="true"></app-headline>
    @if (!(isEmpty | async) && !(isLoading$ | async)) {
      <table>
        <tbody>
        <tr>
          <th>{{ 'documents.projectName' | translate }}</th>
          <th>{{ 'documents.accesses' | translate }}</th>
          <th>{{ 'documents.created' | translate }}</th>
          <th>{{ 'documents.contents' | translate }}</th>
        </tr>
          @for (project of (projects$ | async); track project) {
            <tr>
              <td [routerLink]="[project.id]">
                {{ project.name }}
              </td>
              <td [routerLink]="[project.id]">
                <app-chip [type]="ChipType.PERSON_COUNT" [counter]="mockUserCount"></app-chip>
              </td>
              <td
                [routerLink]="[project.id]">{{ project.updatedDate | date: 'short' }}
              </td>
              <td [routerLink]="[project.id]">
                {{ mockDocumentCount }} {{ mockDocumentCount === 1 ? ('documents.document' | translate) : ('documents.documents' | translate) }}
              </td>
            </tr>
          }
        </tbody>
      </table>
      <app-custom-paginator
        (pageChanged)="onChangePage($event)"
        [totalElements]="totalCount"
        [currentPage]="currentPage"
        [pageSize]="pageSize"
      ></app-custom-paginator>
    }
    @if ((isEmpty | async) && !(isLoading$ | async)) {
      <app-empty-state-list [emptyStateText]="'projects.projectsEmptyState' | translate"></app-empty-state-list>
    }
    @if (isLoading$ | async) {
      <app-spinner></app-spinner>
    }
  </ng-container>
</app-page-layout>
