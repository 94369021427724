import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {UiDocuments} from "../classes/ui-documents";
import {MockFixturesService} from "../../shared/services/mock-fixtures.service";
import {WsModuleResponseDto} from "@fertirob/fertirob-api";
import {FeedbackService} from "../../shared/services/feedback.service";
import {FeedbackType} from "../../shared/enums/feedback-type";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {
  DeleteDocumentPermanentlyOverlayComponent
} from "../components/delete-document-permanently-overlay/delete-document-permanently-overlay.component";
import {
  TimeSettingOverlayComponent
} from "../components/time-setting-overlay/time-setting-overlay.component";

@Injectable({
  providedIn: 'root'
})
export class TrashListService {
  protected _pageSize: number = 10;
  protected _totalCount: number = 0;
  private _trashList: BehaviorSubject<UiDocuments[] | undefined> = new BehaviorSubject<UiDocuments[] | undefined>(undefined);
  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sort: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public storageTime: number = 30;

  constructor(private _mockFixturesService: MockFixturesService, private _feedbackService: FeedbackService, private _dialog: MatDialog) {
  }


  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }
  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public get getTotalCount(): any {
    return this._totalCount;
  }

  public get getPageSize(): any {
    return this._pageSize;
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchTrashList();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchTrashList();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchTrashList();
  }

  public getTrashList = (id?: string): Observable<UiDocuments[] | undefined> => {
    this.fetchTrashList();
    return this._trashList!.asObservable();
  }

  public fetchTrashList = async (): Promise<void> => {
    //TODO Call Service
    this._trashList.next([])
    this._trashList.next(this._mockFixturesService.mockDocumentList());
    this._totalCount = this._mockFixturesService.mockDocumentList().length
  }

  public restore = async (): Promise<void> => {
    try {
      this._feedbackService.setFeedback('snackbar.restoreFile.success', FeedbackType.SUCCESS)
      await this.fetchTrashList();
      return Promise.resolve();
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.restoreFile.failure', FeedbackType.ERROR)
      throw err;
    }
  }

  public deletePermanently = async (): Promise<void> => {
    try {
      this._feedbackService.setFeedback('snackbar.deletePermanently.success', FeedbackType.SUCCESS)
      await this.fetchTrashList();
      return Promise.resolve();
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.deletePermanently.failure', FeedbackType.ERROR)
      throw err;
    }
  }

  public changeStorageTime = async (time: number): Promise<void> => {
    try {
      this.storageTime = time;
      this._feedbackService.setFeedback('snackbar.updateTimeSettings.success', FeedbackType.SUCCESS)
      await this.fetchTrashList();
      return Promise.resolve();
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.updateTimeSettings.failure', FeedbackType.ERROR)
      throw err;
    }
  }
}
