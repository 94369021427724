import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {ButtonType} from '../../../shared/enums/button-enum';
import {
  AssemblyTableRowComponent
} from '../../../modules/components/assembly-table-row/assembly-table-row.component';
import {DatePipe} from '@angular/common';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {
  PartTableRowComponent
} from '../../../modules/components/part-table-row/part-table-row.component';
import {ChipComponent} from '../../../shared/components/chip/chip.component';
import {
  InputWrapperComponent
} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {UiCompletedComponent} from '../../classes/ui-completed-component';
import {MatDialog} from '@angular/material/dialog';
import {
  ComponentDetailsOverlayComponent
} from '../component-details-overlay/component-details-overlay.component';
import {
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum,
  WsParameterResponseDto,
  WsParameterValueResponseDto
} from '@fertirob/fertirob-api';
import {ChipType} from "../../../shared/enums/chip-enum";

@Component({
  selector: 'app-component-table-row-in-project',
  standalone: true,
  imports: [
    AssemblyTableRowComponent,
    DatePipe,
    IconButtonComponent,
    PartTableRowComponent,
    ChipComponent,
    InputWrapperComponent,
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './component-table-row-in-project.component.html',
  styleUrl: './component-table-row-in-project.component.scss'
})
export class ComponentTableRowInProjectComponent implements OnInit {
  @Input() component?: WsComponentResponseDto;
  @Input() projectParameters?: WsParameterValueResponseDto[];
  @Input() enableDelete?: boolean = false;
  @Output() onFormChange: EventEmitter<UiCompletedComponent> = new EventEmitter<UiCompletedComponent>();
  @Output() onFormDirty: EventEmitter<boolean> = new EventEmitter<boolean>();

  public parameterForm: FormGroup;
  public isSelected: boolean = false;
  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  constructor(private fb: FormBuilder,
              private dialog: MatDialog) {
    this.parameterForm = this.fb.group({
      parameters: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.formSetUp();
  }

  get parameterArray(): FormArray {
    return this.parameterForm.get('parameters') as FormArray;
  }

  public formSetUp(): void {
    this.component?.parameters?.forEach((parameter: WsParameterResponseDto) => {
      this.parameterArray.push(this.createParameter(parameter));
    })
    this.emitFormState();

    this.parameterForm.valueChanges.subscribe(value => {
      this.emitFormState();
      this.onFormDirty.emit(true);
    });
  }

  public emitFormState(): void {
    let completedComponent: UiCompletedComponent = {
      id: this.component?.id,
      isCompleted: this.isComponentComplete
    }
    this.emitChange(completedComponent);
  }

  public emitChange(completedComponent: UiCompletedComponent): void {
    this.onFormChange.emit(completedComponent);
  }

  private createParameter = (parameter?: WsParameterResponseDto): FormGroup => {
    let parameterValue: number | undefined = undefined;
    this.projectParameters?.forEach((projectParameter) => {
      if (parameter?.id === projectParameter.parameterId) {
        parameterValue = projectParameter.value;
      }
    })
    return this.fb.group({
      serialNumber: [parameter?.id],
      value: [parameterValue, [Validators.required]],
    });
  }

  public selectComponent(): void {
    this.isSelected = !this.isSelected;
  }

  public hasParameters(component?: WsComponentResponseDto): boolean {
    if (!component || !component?.parameters) {
      return false;
    } else {
      return component?.parameters?.length > 0
    }
  }

  get isComponentComplete(): boolean {
    return this.parameterArray.valid;
  }

  get amountInComplete(): string | undefined {
    let amount = 0;
    for (let control of this.parameterArray.controls) {
      if (!control.valid) {
        amount++;
      }
    }
    return this.isComponentComplete ? undefined : amount + '/' + this.parameterArray.controls.length;
  }

  public onShowComponentDetails(): void {
    const dialogRef = this.dialog.open(ComponentDetailsOverlayComponent);
    dialogRef.componentInstance.component = this.component;
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }

  public hasFiles(): boolean {
    //TODO implement check when component has files attribute
    return true;
  }

  protected readonly ChipType = ChipType;
}
