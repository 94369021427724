import {Component, Input, OnInit} from '@angular/core';
import {ButtonType} from "../../../shared/enums/button-enum";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {MatFormField, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {AssemblyTableRowComponent} from "../assembly-table-row/assembly-table-row.component";
import {PartTableRowComponent} from "../part-table-row/part-table-row.component";
import {ModuleTableRowComponent} from "../module-table-row/module-table-row.component";
import {
  TableInfoRowComponent
} from "../../../shared/components/table-info-row/table-info-row.component";
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {MatDialog} from '@angular/material/dialog';
import {AddComponentOverlayComponent} from '../add-component-overlay/add-component-overlay.component';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {map, Observable} from 'rxjs';
import {LeavePageOverlayComponent} from '../../../shared/components/leave-page-overlay/leave-page-overlay.component';
import {FilterOptionWrapperComponent} from '../../../shared/components/filter-option-wrapper/filter-option-wrapper.component';
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {RouteConstants} from "../../../shared/constants/route-constants";
import {
  WsComponentRequestDto,
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum,
  WsModuleRequestDto,
  WsModuleResponseDto
} from '@fertirob/fertirob-api';
import {ModuleDetailsService} from '../../services/module-details.service';
import {ComponentDetailsService} from '../../services/component-details.service';

@Component({
  selector: 'app-module-general',
  standalone: true,
  imports: [
    ButtonComponent,
    HeadlineComponent,
    MatFormField,
    MatIcon,
    MatInput,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    TranslateModule,
    AssemblyTableRowComponent,
    PartTableRowComponent,
    ModuleTableRowComponent,
    TableInfoRowComponent,
    EmptyStateListComponent,
    InputWrapperComponent,
    FilterOptionWrapperComponent,
    RouterLink
  ],
  templateUrl: './module-general.component.html',
  styleUrl: './module-general.component.scss'
})
export class ModuleGeneralComponent implements OnInit {
  @Input() module?: WsModuleResponseDto;
  public detailForm: FormGroup = new FormGroup({});
  public filterOptions: string[] = [];

  constructor(private _fb: FormBuilder,
              private _componentDetailsService: ComponentDetailsService,
              private _moduleDetailsService: ModuleDetailsService,
              private _dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.detailForm = this._fb.group({
      name: [{value: '', disabled: this.module?.readonly}, Validators.required],
      description: [{value: '', disabled: this.module?.readonly}]
    })
    this.getFilterOptions();
    this.setUpForm();
  }

  public getFilterOptions(): void {
    this.filterOptions = ['Alle', 'Einer', 'Beide'];
  }

  public setUpForm(): void {
    this.detailForm.patchValue({
      name: this.module?.name,
      description: this.module?.description
    })
  }

  get isEmpty(): boolean {
    return this.module?.components?.length === 0;
  }

  public onShowAddComponentOverlay(): void {
    const dialogRef = this._dialog.open(AddComponentOverlayComponent);
    dialogRef.afterClosed().subscribe((result: WsComponentRequestDto) => {
      if (result && this.module?.id) {
        this._componentDetailsService.createComponent(this.module.id, result);
      }
    });
  }

  public onSearchTermChange(searchTerm: string): void {
    //TODO call service
  }

  public onFilterOptionChange(filterOption: string): void {
    //TODO call service
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.detailForm.dirty) {
      const dialogRef = this._dialog.open(LeavePageOverlayComponent);
      return dialogRef.afterClosed().pipe(map(result => result === true));
    }
    return true;
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }

  public onSaveModule(): void {
    let saveModuleRequest: WsModuleRequestDto = {
      id: this.module?.id,
      name: this.detailForm.value.name,
      description: this.detailForm.value.description
    }
    this._moduleDetailsService.updateModule(saveModuleRequest);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
  protected readonly RouteConstants = RouteConstants;
}
