import { Injectable } from '@angular/core';
import {MockFixturesService} from "../../shared/services/mock-fixtures.service";
import {async, BehaviorSubject, Observable} from "rxjs";
import {UiDocuments} from "../classes/ui-documents";
import {WsModuleResponseDto} from '@fertirob/fertirob-api';
import {FeedbackService} from "../../shared/services/feedback.service";
import {FeedbackType} from "../../shared/enums/feedback-type";

@Injectable({
  providedIn: 'root'
})
export class DocumentListService {

  protected _pageSize = 10;
  protected _totalCount: number = 0;
  private _documentList: BehaviorSubject<UiDocuments[] | undefined> = new BehaviorSubject<WsModuleResponseDto[] | undefined>(undefined);
  private _currentPage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _searchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _sort: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _mockFixturesService: MockFixturesService, private _feedbackService: FeedbackService) {
  }


  get isLoading(): Observable<boolean> {
    return this._loading.asObservable()
  }
  get getCurrentPage(): number {
    return this._currentPage.getValue();
  }

  public get getTotalCount(): any {
    return this._totalCount;
  }

  public get getPageSize(): any {
    return this._pageSize
  }

  public setCurrentPage = (currentPage: number): void => {
    this._currentPage.next(currentPage)
    this.fetchDocumentList();
  }

  public get getSearchTerm(): string {
    return this._searchTerm.getValue();
  }

  public setSearchTerm = (searchTerm: string): void => {
    this._searchTerm.next(searchTerm)
    this.fetchDocumentList();
  }

  public get getSort(): string[] {
    return this._sort.getValue();
  }

  public setSort = (sortArray: string[]): void => {
    this._sort.next(sortArray)
    this.fetchDocumentList();
  }

  public getDocumentList = (id?: string): Observable<UiDocuments[] | undefined> => {
    this.fetchDocumentList();
    return this._documentList.asObservable();
  }

  public fetchDocumentList = async (): Promise<void> => {
    //TODO Call Service
    this._documentList.next([])
    this._documentList.next(this._mockFixturesService.mockDocumentList());
    this._totalCount = this._mockFixturesService.mockDocumentList().length
  }

  public deleteDocument = async (): Promise<void> => {
    try {
      this._feedbackService.setFeedback('snackbar.delete.success', FeedbackType.SUCCESS);
      await this.fetchDocumentList();
      return Promise.resolve();
    } catch (err) {
      this._feedbackService.setFeedback('snackbar.delete.failure', FeedbackType.ERROR);
      return Promise.reject(err);
    }
  }

}
