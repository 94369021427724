import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {FeedbackService} from '../services/feedback.service';
import {catchError, Observable, throwError} from 'rxjs';
import {RouteConstants} from '../constants/route-constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private readonly injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const _feedbackService = this.injector.get(FeedbackService)
        const _router = this.injector.get(Router)
        if (err.status === 401) {
          //this.oauthService.logOut();
        }
        if (err.status === 404) {
          /*if (request.context.get(MAIN_REQUEST)) {
              this._router.navigate(['404'], {skipLocationChange: true});
          } else {
              this._feedbackService.errorCodeMessageSetUp(err.status);
          }*/
        }
        if (err.status === 403) {
          _router.navigate([RouteConstants.routeAuthorizationError], {skipLocationChange: true});
        }
        if (err.status === 500) {
          _feedbackService.setErrorCodeMessage(err.status);
        }
        return throwError(() => err);
      })
    );
  }
}
