<div class="tab-bar-wrapper">
  @for (state of states; track state; let i = $index) {
    <div class="item"
         [routerLink]="state.link"
         [class.active]="activeState == state">
      <span>{{ getStateTranslation(state.label) | translate }}</span>
      @if (state.isExternalLink) {
        <i class="material-icons bold-weight">open_in_new</i>
      }
    </div>
  }
</div>
