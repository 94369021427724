import {Component, OnInit} from '@angular/core';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {OverlayComponent} from '../../../shared/components/overlay/overlay.component';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {RouteConstants} from "../../../shared/constants/route-constants";
import {RouterLink} from "@angular/router";
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {
  WsComponentRequestDto,
  WsComponentRequestDtoTypeEnum,
} from '@fertirob/fertirob-api';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio';

@Component({
  selector: 'app-add-component-overlay',
  standalone: true,
  imports: [
    IconButtonComponent,
    OverlayComponent,
    TranslateModule,
    ButtonComponent,
    ButtonComponent,
    RouterLink,
    HeadlineComponent,
    InputWrapperComponent,
    ReactiveFormsModule,
    MatRadioGroup,
    MatRadioButton
  ],
  templateUrl: './add-component-overlay.component.html',
  styleUrl: './add-component-overlay.component.scss'
})
export class AddComponentOverlayComponent implements OnInit {

  public addForm: FormGroup;

  constructor(private _fb: FormBuilder, private _dialogRef: MatDialogRef<AddComponentOverlayComponent>) {
    this.addForm = this._fb.group({
      type: [WsComponentRequestDtoTypeEnum.PART, Validators.required],
      name: ['', Validators.required],
      description: ''
    })
  }

  ngOnInit(): void {
  }

  onHideOverlay = (): void => {
    this._dialogRef.close(false);
  }

  onSubmit = (): void => {
    let componentRequest: WsComponentRequestDto = {
      parentId: "",
      type: this.addForm.value.type,
      name: this.addForm.value.name,
      description: this.addForm.value.description
    }
    this._dialogRef.close(componentRequest);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
  protected readonly RouteConstants = RouteConstants;
  protected readonly WsComponentRequestDtoTypeEnum = WsComponentRequestDtoTypeEnum;
}
