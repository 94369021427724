import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {OverlayComponent} from '../../../shared/components/overlay/overlay.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {MatFormField, MatInput} from '@angular/material/input';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {WsModuleRequestDto} from '@fertirob/fertirob-api';

@Component({
  selector: 'app-add-module-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    OverlayComponent,
    TranslateModule,
    MatInput,
    ReactiveFormsModule,
    MatFormField,
    InputWrapperComponent,
    HeadlineComponent
  ],
  templateUrl: './add-module-overlay.component.html',
  styleUrl: './add-module-overlay.component.scss'
})
export class AddModuleOverlayComponent implements OnInit {

  public addForm: FormGroup;

  constructor(private _fb: FormBuilder, private _dialogRef: MatDialogRef<AddModuleOverlayComponent>) {
    this.addForm = this._fb.group({
      name: ['', Validators.required],
      description: ['']
    })
  }

  ngOnInit(): void {
  }

  onHideOverlay = (): void => {
    this._dialogRef.close(undefined);
  }

  onSubmit = (): void => {
    let moduleRequest: WsModuleRequestDto = {
      name: this.addForm.value.name,
      description: this.addForm.value.description,
    }
    this._dialogRef.close(moduleRequest);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
