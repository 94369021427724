import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadCrumbNavigationComponent} from '../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {ModuleTableRowComponent} from '../../../modules/components/module-table-row/module-table-row.component';
import {PageLayoutComponent} from '../../../shared/components/page-layout/page-layout.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ButtonType} from '../../../shared/enums/button-enum';
import {AsyncPipe, DatePipe} from '@angular/common';
import {ChipComponent} from '../../../shared/components/chip/chip.component';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {RouterLink} from '@angular/router';
import {map, Observable, Subject, takeUntil} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {AddProjectOverlayComponent} from '../add-project-overlay/add-project-overlay.component';
import {ProjectListService} from '../../services/project-list.service';
import {
  WsParameterResponseDto,
  WsProjectResponseDto,
  WsParameterValueResponseDto,
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum, WsModuleResponseDto
} from '@fertirob/fertirob-api';
import {ProjectTableRowComponent} from '../project-table-row/project-table-row.component';
import {UiBreadCrumbItem} from '../../../shared/classes/ui-bread-crumb-item';
import {SpinnerComponent} from '../../../shared/components/spinner/spinner.component';
import {CustomPaginatorComponent} from '../../../shared/components/custom-paginator/custom-paginator.component';

@Component({
  selector: 'app-page-project-list',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    ButtonComponent,
    EmptyStateListComponent,
    HeadlineComponent,
    ModuleTableRowComponent,
    PageLayoutComponent,
    TranslateModule,
    DatePipe,
    ChipComponent,
    IconButtonComponent,
    RouterLink,
    ProjectTableRowComponent,
    AsyncPipe,
    SpinnerComponent,
    CustomPaginatorComponent
  ],
  templateUrl: './page-project-list.component.html',
  styleUrl: './page-project-list.component.scss'
})
export class PageProjectListComponent implements OnInit, OnDestroy {
  public projects$: Observable<WsProjectResponseDto[]> = this._projectListService.getProjectList();
  public isLoading$: Observable<boolean> = this._projectListService.isLoading;

  public breadCrumbItems: UiBreadCrumbItem[] = [];
  private _notifier = new Subject<void>();

  constructor(
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _projectListService: ProjectListService
  ) {
  }

  get pageSize(): number {
    return this._projectListService.getPageSize;
  }

  get currentPage(): number {
    return this._projectListService.getCurrentPage;
  }

  get totalCount(): number {
    return this._projectListService.getTotalCount;
  }

  ngOnInit(): void {
    this._projectListService.fetchProjectList();
    this._translate.get(['projects.projects']).subscribe((translations => {
      this.breadCrumbItems.push(
        {label: translations['projects.projects']}
      )
    }))
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  get isEmpty(): Observable<boolean> {
    return this.projects$.pipe(
      map(projects => projects.length === 0)
    );
  }

  public onShowCreateModuleOverlay(): void {
    const dialogRef = this._dialog.open(AddProjectOverlayComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this._projectListService.saveProject(result)
      }
    });
  }

  public onChangePage(page: number): void {
    this._projectListService.setCurrentPage(page);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
