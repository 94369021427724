@if (totalPages > 1) {
  <div class="pagination-wrapper">
    <app-icon-button [type]="ButtonType.PLAIN" [isDisabled]="onFirstPage" [iconName]="IconEnum.CHEVRON_LEFT" (click)="onClickPageNumber(currentPage - 1)"></app-icon-button>
    @if (!shownIndicatorsIncludeFirstPage) {
      <div class="page" [class.active]="currentPage === 0"
           (click)="onClickPageNumber(0)">{{ 1 }}
      </div>
      <i class="material-icons">{{ IconEnum.MORE_HORIZ }}</i>
    }
    @for (pageNumber of pageNumbers; track pageNumber) {
      <div class="page" [class.active]="pageNumber === currentPage" (click)="onClickPageNumber(pageNumber)">{{ pageNumber + 1 }}</div>
    }
    @if (!shownIndicatorsIncludeLastPage) {
      <i class="material-icons">{{ IconEnum.MORE_HORIZ }}</i>
      <div class="page" [class.active]="currentPage === (totalPages - 1)"
           (click)="onClickPageNumber(totalPages - 1)">{{ totalPages }}
      </div>
    }
    <app-icon-button [type]="ButtonType.PLAIN" [isDisabled]="onLastPage" [iconName]="IconEnum.CHEVRON_RIGHT" (click)="onClickPageNumber(currentPage + 1)"></app-icon-button>
  </div>
}

