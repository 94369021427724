import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom, Observable} from 'rxjs';
import {MockFixturesService} from '../../shared/services/mock-fixtures.service';
import {ProjectsApiService, WsProjectResponseDto} from '@fertirob/fertirob-api';

@Injectable({
  providedIn: 'root'
})
export class ProjectDetailsService {

  private _project: BehaviorSubject<WsProjectResponseDto | undefined> = new BehaviorSubject<WsProjectResponseDto | undefined>(undefined);

  constructor(
    private _projectApiService: ProjectsApiService,
    private _mockFixturesService: MockFixturesService
  ) {
  }

  public getProject = (): Observable<WsProjectResponseDto | undefined> => {
    return this._project.asObservable();
  }

  public fetchProject = async (id: string): Promise<void> => {
   /* if (!id || id === this._project.getValue()?.id) {
      return
    }
    this._project.next(undefined);
    try {
      const result = await firstValueFrom(this._projectApiService.getProject(id))

      if (result) {
        this._project.next(result)
      }
    } catch (err) {
      throw err
    }*/
    //TODO Remove mock data
    this._project.next(this._mockFixturesService.mockProject());
  }

  public exportProject = (projectId: string, name: string, format: string): Observable<any> => {
    //TODO Call Service
    return new Observable();
  }
}
