<form class="filter-options-wrapper" [formGroup]="filterForm">
  <mat-form-field>
    <input formControlName="searchTerm" matInput [placeholder]="searchPlaceholder">
    <mat-icon matPrefix class="material-icons">{{ IconEnum.SEARCH }}</mat-icon>
  </mat-form-field>
  <mat-form-field>
    <mat-select formControlName="selectedOption">
      @for (option of filterOptions; track option) {
        <mat-option [value]="option">{{ option }}</mat-option>
      }
    </mat-select>
    <mat-icon matSuffix class="material-icons">{{ IconEnum.ARROW_DOWN }}</mat-icon>
  </mat-form-field>
</form>
