<div class="step-bar-wrapper">
  @for (step of stepBarItems; track step; let last = $last; let first = $first) {
    <div class="step-wrapper">
      @if (!first && step === activeState) {
        <div class="triangle"></div>
      }
      <div class="step" [class.active]="step === activeState">
        <span>{{ "navigation." + step.label | translate }}</span>
      </div>
      @if (!last && step === activeState) {
        <div class="triangle active"></div>
      }
    </div>
  }
</div>
