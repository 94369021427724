<div class="chips-container">
  <div [class.new-version]="type==ChipType.NEW_VERSION"
       [class.new-comment]="type==ChipType.NEW_COMMENT"
       [class.complete]="type==ChipType.COMPLETE"
       [class.in-complete]="type==ChipType.IN_COMPLETE"
       [class.person-count]="type==ChipType.PERSON_COUNT"
       class="chip-content">
    @if (type === ChipType.COMPLETE || type === ChipType.IN_COMPLETE) {
      <i class="material-icons">{{ (ChipType.COMPLETE ? IconEnum.CHECK : IconEnum.EXCLAMATION) }}</i>
    }
    @if (type === ChipType.PERSON_COUNT) {
      <i class="material-icons">{{ IconEnum.PERSON }}</i>
    }
    <span>
      @if (type === ChipType.PERSON_COUNT) {
        {{ counter }}
      }
      {{ getTranslatedText() | translate }}</span>
    @if (type === ChipType.COMPLETE || type === ChipType.IN_COMPLETE) {
      <span>{{ counter }}</span>
    }
  </div>
</div>



