import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {MatFormField, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {MatSort, MatSortHeader, Sort} from "@angular/material/sort";
import {MatAutocomplete, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {map, Observable, startWith} from "rxjs";
import {AsyncPipe, DatePipe} from "@angular/common";
import {
  EmptyStateListComponent
} from "../../../shared/components/empty-state-list/empty-state-list.component";
import {ModuleTableRowComponent} from "../module-table-row/module-table-row.component";
import {
  InputWrapperComponent
} from "../../../shared/components/input/input-wrapper/input-wrapper.component";
import {
  WsComponentResponseDto,
  WsModuleResponseDto,
  WsParameterResponseDto,
  WsRuleResponseDto
} from '@fertirob/fertirob-api';
import {
  SelectionBarComponent
} from '../../../shared/components/selection-bar/selection-bar.component';
import {MockFixturesService} from '../../../shared/services/mock-fixtures.service';
import {
  LeavePageOverlayComponent
} from '../../../shared/components/leave-page-overlay/leave-page-overlay.component';
import {MatDialog} from '@angular/material/dialog';
import {TagifyInputComponent} from '../../../shared/components/tagify-input/tagify-input.component';
import {
  DeleteOverlayComponent
} from "../../../shared/components/delete-overlay/delete-overlay.component";

@Component({
  selector: 'app-module-rules',
  standalone: true,
  imports: [
    FormsModule,
    HeadlineComponent,
    TranslateModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSuffix,
    ButtonComponent,
    ReactiveFormsModule,
    MatCheckbox,
    IconButtonComponent,
    MatSort,
    MatSortHeader,
    MatAutocomplete,
    MatAutocompleteTrigger,
    AsyncPipe,
    EmptyStateListComponent,
    ModuleTableRowComponent,
    DatePipe,
    InputWrapperComponent,
    SelectionBarComponent,
    TagifyInputComponent
  ],
  templateUrl: './module-rules.component.html',
  styleUrl: './module-rules.component.scss'
})
export class ModuleRulesComponent implements OnInit {
  @Input() module?: WsModuleResponseDto;

  public parameterList: string[] = [];

  public ruleForm: FormGroup;
  public parameterFilteredOptions?: Observable<string[]>;

  public invalidFormula: boolean = false;

  public selectionMode: boolean = false;
  public checkedRules: string[] = [];

  public ruleValueToEdit: string | undefined = undefined
  public rule: any = undefined;
  public onResetRule: boolean = false;

  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;

  constructor(private _fb: FormBuilder,
              private _mockFixtureService: MockFixturesService,
              private _dialog: MatDialog,
              private _translate: TranslateService) {
    this.ruleForm = this._fb.group({
      parameter: ['', Validators.required],
    })
  }

  ngOnInit() {
    this.module = this._mockFixtureService.mockModule();
    if (this.module?.components) {
      this.createParameterStringList(this.module?.components);
    }
    this.setUpParameterFilter();
  }

  public sortData($event: Sort) {
    //TODO
  }

  get isEmpty(): boolean {
    return !this.module?.rules || this.module?.rules?.length === 0;
  }

  private setUpParameterFilter(): void {
    this.parameterFilteredOptions = this.ruleForm.get('parameter')?.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value || '')),
    );
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.parameterList.filter(option => option.toLowerCase().includes(filterValue));
  }

  public createParameterStringList(components: WsComponentResponseDto[]): void {
    components?.forEach((component: WsComponentResponseDto) => {
      component.parameters?.forEach((parameter: WsParameterResponseDto) => {
        if (parameter.name) {
          this.parameterList.push(parameter.name);
        }
      })
      if (component.subComponents) {
        this.createParameterStringList(component.subComponents);
      }
    })
  }

  get disableAddRuleButton(): boolean {
    if (!this.rule) {
      return true
    }
    let ruleIsEmpty = this.removeNonPrintableCharacters(this.rule.tagify.DOM.input.textContent.trim()) === '';
    return this.ruleForm.get('parameter')?.invalid ||
      this.ruleForm.pristine || ruleIsEmpty
  }

  public onAddRule(): void {
    let formula = this.rule.tagify.DOM.input.textContent.trim();
    let cleanedFormula = this.removeNonPrintableCharacters(formula);
    let formulaRegex = this.createFormulaRegex();

    if (formulaRegex.test(cleanedFormula)) {
      console.log(cleanedFormula);
      //TODO send cleanedFormula to be
      this.resetRuleForm();
    } else {
      this.invalidFormula = true;
    }
  }

  public resetRuleForm(): void {
    this.invalidFormula = false;
    this.ruleValueToEdit = undefined;
    this.ruleForm.patchValue({
      parameter: '',
      name: []
    });
    this.ruleForm.markAsPristine();
    this.onResetRule = !this.onResetRule;
  }

  public createFormulaRegex(): RegExp {
    const escapedVariables = this.parameterList.map(v => v?.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));
    const variablesPattern = escapedVariables.join('|');

    const regexPattern = `^\\s*(-?\\d+(\\.\\d+)?|${variablesPattern}|\\(([^()]*)\\))(\\s*[-+*/]\\s*(-?\\d+(\\.\\d+)?|${variablesPattern}|\\(([^()]*)\\)))*\\s*$`;
    return new RegExp(regexPattern);
  }

  public removeNonPrintableCharacters(str: string): string {
    return str.replace(/[\u200B-\u200D\uFEFF]/g, '');
  }

  public onEditRule(rule: WsRuleResponseDto): void {
    if (rule.value) {
      this.ruleForm.patchValue({
        parameter: rule.parameterName,
      })
      this.ruleForm.markAsDirty();
      this.ruleValueToEdit = this.encloseParametersWithBrackets(rule.value);
    }
  }

  public encloseParametersWithBrackets(inputString: string): string {
    //TODO dont use this.parameterList, BE will add specific parameterList to each rule
    const escapedParameters = this.parameterList.map(param =>
      param.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    );

    const parametersPattern = escapedParameters.join('|');
    const regexPattern = new RegExp(`\\b(${parametersPattern})\\b`, 'g');

    return inputString.replace(regexPattern, '[[$1]]');
  }

  public onRuleChange($event: any): void {
    this.rule = $event;
  }

  public onChangeParameter(event: any): void {
    let isInList = this.parameterList.includes(event.target.value);
    if (!isInList) {
      this.ruleForm.patchValue({
        parameter: ''
      })
    }
  }

  public onCheckboxCheck(checked: boolean, paramId: string | undefined): void {
    if (!paramId) return;
    if (checked) {
      this.checkedRules.push(paramId);
    } else {
      this.checkedRules = this.checkedRules.filter(param => param !== paramId);
    }
  }

  public onCheckAll(checked: boolean): void {
    this.checkedRules = [];
    if (checked) {
      this.module?.rules?.forEach((rule) => {
        if (rule.id) {
          this.checkedRules.push(rule.id);
        }
      })
    }
  }

  public isChecked(paramId?: string): boolean {
    if (!paramId) return false;
    return this.checkedRules.includes(paramId);
  }

  public areAllChecked(): boolean {
    if (this.checkedRules.length === 0) return false;
    return this.checkedRules.length === this.module?.rules?.length;
  }

  public areSomeChecked(): boolean {
    if (this.checkedRules.length === 0) return false;
    if (!this.module?.rules) return false;
    return this.checkedRules.length < this.module?.rules?.length;
  }

  public toggleSelectionMode(mode: boolean) {
    this.selectionMode = mode;
    if (!this.selectionMode) {
      this.checkedRules = [];
    }
  }

  public onDownload(): void {
    //TODO download selected items
  }


  public deleteOverlay(value?: WsRuleResponseDto): void {
    const dialogRef = this._dialog.open(DeleteOverlayComponent);

    if (value) {
      dialogRef.componentInstance.values = `${value!.parameterName} = ${value!.value}`;
    } else {
      dialogRef.componentInstance.values = this.module?.rules?.filter(rule => this.checkedRules.includes(rule.id ?? '')).map(rule => `${rule.parameterName} = ${rule.value}`).join(', ');
    }
    dialogRef.componentInstance.headline = this._translate.instant('modules.delete.deleteRule');
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.ruleForm.touched) {
      const dialogRef = this._dialog.open(LeavePageOverlayComponent);
      return dialogRef.afterClosed().pipe(map(result => result === true));
    }
    return true;
  }
}
