import {Component, EventEmitter, Input, OnInit, output, Output} from '@angular/core';
import {MatCheckbox} from "@angular/material/checkbox";
import {ChipComponent} from "../../../shared/components/chip/chip.component";
import {ChipType} from "../../../shared/enums/chip-enum";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {map, Observable} from "rxjs";
import {UiDocuments} from "../../classes/ui-documents";
import {AsyncPipe, DatePipe} from "@angular/common";
import {DocumentListService} from "../../services/document-list.service";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {
  UploadNewVersionOverlayComponent
} from "../upload-new-version-overlay/upload-new-version-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {CdkDrag, CdkDragHandle} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-document-table-row',
  standalone: true,
  imports: [
    MatCheckbox,
    ChipComponent,
    AsyncPipe,
    IconButtonComponent,
    MatMenuTrigger,
    MatMenu,
    MatIcon,
    MatMenuItem,
    DatePipe,
    TranslateModule,
    CdkDragHandle,
    CdkDrag
  ],
  templateUrl: './document-table-row.component.html',
  styleUrl: './document-table-row.component.scss'
})
export class DocumentTableRowComponent implements OnInit{
  @Input() document?: UiDocuments;
  @Input() selectionMode: boolean = false;
  @Output() showVersionsChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() showCommentsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  showVersions: boolean = false;
  showComments: boolean = false;

  public checkedDocuments: string[] = [];

  constructor(private dialog: MatDialog, private documentListService: DocumentListService, private translate: TranslateService) {
  }

  ngOnInit() {
    /*this.documents$ = this.documentListService.getDocumentList().pipe(
      map(documents => documents?.filter(document => document.id === this.document))
    );*/
  }

  public onCheckboxCheck(checked: boolean, documentId: string | undefined): void {
    if (!documentId) return;
    if (checked) {
      this.checkedDocuments.push(documentId);
    } else {
      this.checkedDocuments = this.checkedDocuments.filter(doc => doc !== documentId);
    }
  }

  public isChecked(documentId?: string): boolean {
    if (!documentId) return false;
    return this.checkedDocuments.includes(documentId);
  }

  protected readonly ChipType = ChipType;
  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;

  onDelete() {
    //TODO delete selected items
    this.documentListService.deleteDocument()
  }

  onShowVersions() {
    this.showVersionsChange.emit(!this.showVersions);
    this.showCommentsChange.emit(this.showComments)
  }

  onShowComments() {
    this.showCommentsChange.emit(!this.showComments);
    this.showVersionsChange.emit(this.showVersions)
  }

  showUploadOverlay() {
    const dialogRef = this.dialog.open(UploadNewVersionOverlayComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.getDocuments();
      }
    });
  }
}
