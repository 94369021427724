import {Component, Input} from '@angular/core';
import {ButtonComponent} from "../button/button.component";
import {HeadlineComponent} from "../headline/headline.component";
import {IconButtonComponent} from "../icon-button/icon-button.component";
import {OverlayComponent} from "../overlay/overlay.component";
import {TranslateModule} from "@ngx-translate/core";
import {ButtonType} from "../../enums/button-enum";
import {IconEnum} from "../../enums/icon-enum";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-delete-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    HeadlineComponent,
    IconButtonComponent,
    OverlayComponent,
    TranslateModule
  ],
  templateUrl: './delete-overlay.component.html',
  styleUrl: './delete-overlay.component.scss'
})
export class DeleteOverlayComponent {
  public headline: string = 'default.deleteHeadline';
  public description?: string;
  public values?: string;

  constructor(public dialogRef: MatDialogRef<DeleteOverlayComponent>) {
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  delete() {
    this.dialogRef.close(true);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
}
