import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageLayoutComponent} from "../../../shared/components/page-layout/page-layout.component";
import {
  BreadCrumbNavigationComponent
} from "../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {ChipComponent} from "../../../shared/components/chip/chip.component";
import {AsyncPipe, DatePipe} from "@angular/common";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {RouterLink} from "@angular/router";
import {map, Observable, Subject, takeUntil} from "rxjs";
import {ProjectListService} from "../../../projects/services/project-list.service";
import {RouteConstants} from "../../../shared/constants/route-constants";
import {UiBreadCrumbItem} from "../../../shared/classes/ui-bread-crumb-item";
import {WsProjectResponseDto} from "@fertirob/fertirob-api";
import {MatCheckbox} from "@angular/material/checkbox";
import {ChipType} from "../../../shared/enums/chip-enum";
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {SpinnerComponent} from '../../../shared/components/spinner/spinner.component';
import {CustomPaginatorComponent} from '../../../shared/components/custom-paginator/custom-paginator.component';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle, CdkDragPreview,
  CdkDropList,
  moveItemInArray
} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-page-document-project-list',
  standalone: true,
  imports: [
    PageLayoutComponent,
    BreadCrumbNavigationComponent,
    TranslateModule,
    HeadlineComponent,
    ChipComponent,
    DatePipe,
    IconButtonComponent,
    RouterLink,
    MatCheckbox,
    AsyncPipe,
    EmptyStateListComponent,
    SpinnerComponent,
    CustomPaginatorComponent,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPreview
  ],
  templateUrl: './page-document-project-list.component.html',
  styleUrl: './page-document-project-list.component.scss'
})
export class PageDocumentProjectListComponent implements OnInit, OnDestroy {
  public projects$: Observable<WsProjectResponseDto[]> = this._projectListService.getProjectList();
  public isLoading$: Observable<boolean> = this._projectListService.isLoading;

  public breadCrumbItems: UiBreadCrumbItem[] = [];
  public mockUserCount: number = 10;
  public mockDocumentCount: number = 3;
  private _notifier = new Subject<void>();

  constructor(
    private _projectListService: ProjectListService,
    private _translate: TranslateService
  ) {
  }

  get pageSize(): number {
    return this._projectListService.getPageSize;
  }

  get currentPage(): number {
    return this._projectListService.getCurrentPage;
  }

  get totalCount(): number {
    return this._projectListService.getTotalCount;
  }

  ngOnInit(): void {
    this._projectListService.fetchProjectList();
    this._translate.get(['documents.documents']).subscribe((translations => {
      this.breadCrumbItems = [
        {label: translations['documents.documents']},
      ];
    }))
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  get isEmpty(): Observable<boolean> {
    return this.projects$.pipe(
      map(projects => projects.length === 0)
    );
  }

  public onChangePage(page: number): void {
    this._projectListService.setCurrentPage(page);
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
  protected readonly RouterLink = RouterLink;
  protected readonly RouteConstants = RouteConstants;
  protected readonly ChipType = ChipType;
  protected readonly document = document;
}
