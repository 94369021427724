import {Component, Inject, Input, OnInit} from '@angular/core';
import {OverlayComponent} from "../../../shared/components/overlay/overlay.component";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {TranslateModule} from "@ngx-translate/core";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {ButtonType} from "../../../shared/enums/button-enum";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  InputWrapperComponent
} from "../../../shared/components/input/input-wrapper/input-wrapper.component";
import {MatFormField, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {ButtonComponent} from "../../../shared/components/button/button.component";

@Component({
  selector: 'app-time-setting-overlay',
  standalone: true,
  imports: [
    OverlayComponent,
    HeadlineComponent,
    TranslateModule,
    IconButtonComponent,
    InputWrapperComponent,
    MatFormField,
    MatIcon,
    MatOption,
    MatSelect,
    MatSuffix,
    ReactiveFormsModule,
    ButtonComponent,
  ],
  templateUrl: './time-setting-overlay.component.html',
  styleUrl: './time-setting-overlay.component.scss'
})
export class TimeSettingOverlayComponent implements OnInit {
  public storageTime: number = 0;
  public timeOptions: number[] = [7, 14, 30, 60, 90];
  public storageTimeForm: FormGroup = new FormGroup({})

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _fb: FormBuilder, private _dialogRef: MatDialogRef<TimeSettingOverlayComponent>) {
    this.storageTime = data.storageTimeSetting;
  }

  ngOnInit() {
    this.storageTimeForm = this._fb.group({
      days: [this.storageTime]
    })
  }

  onHideOverlay = (): void => {
    this._dialogRef.close(false);
  }

  onSubmit = (): void => {
    this.storageTime = this.storageTimeForm.value.days;
    this._dialogRef.close({storageTimeSetting: this.storageTime});
  }

  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;
}
