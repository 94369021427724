<div class="table-row" [class.no-router-link]="disableRouterLink" #row>
  <div class="table-cell">
    <div class="offset-element" #offsetElement></div>
    <app-icon-button (click)="selectAssembly()"
                     [iconName]="isSelected ? IconEnum.ARROW_UP : IconEnum.ARROW_DOWN"
                     [type]="ButtonType.PLAIN"></app-icon-button>
    <a
      [routerLink]="disableRouterLink ? [] : ['/', RouteConstants.routeModules, RouteConstants.routeNoId, assembly?.id]">{{ assembly?.name }}</a>
  </div>
  <div class="table-cell"
       [routerLink]="disableRouterLink ? [] : ['/', RouteConstants.routeModules, RouteConstants.routeNoId, assembly?.id]">
    @if (!hideDate) {
      {{ assembly?.updatedDate | date: 'short' }}
    }
  </div>
  <div class="table-cell">
    <app-icon-button [isDisabled]="!enableDelete" [iconName]="IconEnum.DELETE" [type]="ButtonType.PLAIN" (click)="deleteOverlay(assembly)"></app-icon-button>
  </div>
</div>
@if (isSelected) {
  @for (component of assembly?.subComponents; track component) {
    @if (isAssembly(component.type)) {
      <app-assembly-table-row [assembly]="component" [offset]="newOffset" [enableDelete]="enableDelete" [hideDate]="hideDate"
                              [disableRouterLink]="disableRouterLink"></app-assembly-table-row>
    } @else {
      <app-part-table-row [part]="component" [offset]="newOffset" [enableDelete]="enableDelete" [hideDate]="hideDate"
                          [disableRouterLink]="disableRouterLink"></app-part-table-row>
    }
  }
}
