<div class="table-row" [class.no-router-link]="disableRouterLink" #row>
  <div class="table-cell">
    <div class="offset-element" #offsetElement></div>
    <span class="material-icons">{{ IconEnum.PART }}</span>
    <a [routerLink]="disableRouterLink ? [] : ['/', RouteConstants.routeModules, RouteConstants.routeNoId, part?.id]">{{ part?.name }}</a>
  </div>
  <div class="table-cell" [routerLink]="disableRouterLink ? [] : ['/', RouteConstants.routeModules, RouteConstants.routeNoId, part?.id]">
    @if (!hideDate) {
      {{ part?.updatedDate | date: 'short' }}
    }
  </div>
  <div class="table-cell">
    <app-icon-button [isDisabled]="!enableDelete" [iconName]="IconEnum.DELETE" [type]="ButtonType.PLAIN" (click)="deleteOverlay(part)"></app-icon-button>
  </div>
</div>
