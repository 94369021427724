import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UiStepBarItem} from '../../classes/ui-step-bar-item';
import {StepBarEnum} from '../../enums/step-bar-enum';
import {TranslateModule} from '@ngx-translate/core';
import {UiTabBar} from "../../classes/ui-tab-bar";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-step-bar',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './step-bar.component.html',
  styleUrl: './step-bar.component.scss'
})
export class StepBarComponent {
  @Input() stepBarItems: UiTabBar[] = [];
  @Input() activeState?: UiTabBar;
  @Output() activeStateChange = new EventEmitter<UiTabBar>();


  constructor(private _router: Router,
              private _route: ActivatedRoute) {
  }
  ngOnInit(): void {
    this._route.url.subscribe(
      (url) => {
        let activeState: UiTabBar = this.stepBarItems[0]
        const urlPathArray = url.map(e => e.path)

        if (urlPathArray.length > 0) {
          this.stepBarItems.forEach(s => {
            if (s.link.includes(urlPathArray[0])) {
              activeState = s
              return;
            }
          })
        }

        this.changeActiveState(activeState);
      }
    );
  }


  private changeActiveState(activeState: UiTabBar): void {
    const state = activeState ? (this.stepBarItems?.includes(activeState) ? activeState : (this.stepBarItems ? this.stepBarItems[0] : undefined)) : undefined;
    this.activeState = state;
    this.activeStateChange.emit(state);
  }
}
