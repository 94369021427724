import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PartTableRowComponent} from '../part-table-row/part-table-row.component';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {DatePipe} from '@angular/common';
import {RouterLink} from '@angular/router';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {TableHelperService} from '../../../shared/services/table-helper.service';
import {RouteConstants} from "../../../shared/constants/route-constants";
import {
  ComponentsApiService,
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum,
  WsParameterResponseDto
} from '@fertirob/fertirob-api';
import {
  DeleteOverlayComponent
} from "../../../shared/components/delete-overlay/delete-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {ComponentDetailsService} from "../../services/component-details.service";

@Component({
  selector: 'app-assembly-table-row',
  standalone: true,
  imports: [
    PartTableRowComponent,
    DatePipe,
    RouterLink,
    IconButtonComponent
  ],
  templateUrl: './assembly-table-row.component.html',
  styleUrl: './assembly-table-row.component.scss'
})
export class AssemblyTableRowComponent implements OnInit {
  @Input() assembly?: WsComponentResponseDto;
  @Input() enableDelete?: boolean;
  @Input() hideDate?: boolean;
  @Input() disableRouterLink?: boolean;

  @Input() set offset(val: number) {
    this.newOffset = val + 1;
  }

  @ViewChild('offsetElement') offsetElement?: ElementRef;
  @ViewChild('row') row?: ElementRef;

  public newOffset: number = 0;
  public isSelected: boolean = false;

  constructor(private _tableHelperService: TableHelperService, private _dialog: MatDialog, private _translate: TranslateService, private _componentDetailService: ComponentDetailsService) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this._tableHelperService.setTableStyling(this.offsetElement, this.row, this.newOffset);
    }, 10);
  }

  protected readonly IconEnum = IconEnum;

  public selectAssembly(): void {
    this.isSelected = !this.isSelected;
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }

  protected readonly ButtonType = ButtonType;
  protected readonly RouteConstants = RouteConstants;

  public deleteOverlay(value: WsParameterResponseDto | undefined) {
    const dialogRef = this._dialog.open(DeleteOverlayComponent)
    dialogRef.componentInstance.values = value!.name;
    dialogRef.componentInstance.headline = this._translate.instant('modules.delete.deleteAssemblyGroup');

    return dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._componentDetailService.deleteComponent(this.assembly?.id!)
      }
    })
  }
}
