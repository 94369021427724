<div class="table-row four-column">
  <div class="table-cell">
      <div class="flex-content-wrapper">
        @if (selectionMode) {
          <mat-checkbox (change)="onCheckboxCheck($event.checked, document?.id)"
                        [checked]="isChecked(document?.id)"></mat-checkbox>
        }
        <i
          class="material-icons">{{ document?.isFile ? IconEnum.DOCUMENTS : IconEnum.FOLDER }}</i>
        {{ document?.name }}
        <app-chip [type]="ChipType.NEW_COMMENT"></app-chip>
      </div>
  </div>
  @if (!showVersions && !showComments) {
    <div class="table-cell">{{ document?.responsibleUser }}</div>
    <div class="table-cell">{{ document?.updatedDate | date: 'short' }}</div>
  }
  <div class="table-cell">
    <div class="flex-content-wrapper">
      <app-icon-button [iconName]="IconEnum.DOWNLOAD"
                       [type]="ButtonType.PLAIN"></app-icon-button>
      <app-icon-button [iconName]="IconEnum.MORE"
                       [type]="ButtonType.PLAIN"
                       [matMenuTriggerFor]=" menu"></app-icon-button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onDelete()">
          <mat-icon class="material-icons">{{ IconEnum.DELETE }}</mat-icon>
          {{ 'documents.dropdown.delete' | translate }}
        </button>
        <button mat-menu-item>
          <mat-icon class="material-icons">{{ IconEnum.DOWNLOAD }}</mat-icon>
          {{ 'documents.dropdown.download' | translate }}
        </button>
        @if (document?.isFile) {
          <button mat-menu-item (click)="onShowVersions()">
            <mat-icon class="material-icons">{{ IconEnum.HISTORY }}</mat-icon>
            {{ 'documents.dropdown.version' | translate }}
          </button>
          <button mat-menu-item (click)="onShowComments()">
            <mat-icon class="material-icons">{{ IconEnum.COMMENT }}</mat-icon>
            {{ 'documents.dropdown.comment' | translate }}
          </button>
          <button mat-menu-item (click)="showUploadOverlay()">
            <mat-icon class="material-icons">{{ IconEnum.UPLOAD }}</mat-icon>
            {{ 'documents.dropdown.upload' | translate }}
          </button>
        }
      </mat-menu>
    </div>
  </div>
</div>
