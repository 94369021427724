import {Component, Input} from '@angular/core';
import {ButtonType} from "../../enums/button-enum";

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() type: ButtonType = ButtonType.PRIMARY;
  @Input() label?: string;
  @Input() isDisabled?: boolean;
  @Input() isSmall?: boolean;
  @Input() fitWidth?: boolean;

  protected readonly ButtonType = ButtonType;
}
