import {Component, Input} from '@angular/core';
import {BreadCrumbNavigationComponent} from '../bread-crumb-navigation/bread-crumb-navigation.component';

@Component({
  selector: 'app-page-layout',
  standalone: true,
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.scss'
})
export class PageLayoutComponent {
  @Input() noButtons?: boolean;
}
