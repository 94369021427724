import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {
  BreadCrumbNavigationComponent
} from "../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component";
import {AsyncPipe, DatePipe} from "@angular/common";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {PageLayoutComponent} from "../../../shared/components/page-layout/page-layout.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {RouteConstants} from "../../../shared/constants/route-constants";
import {DocumentListService} from "../../services/document-list.service";
import {Observable, of, Subject, takeUntil} from "rxjs";
import {UiDocuments} from "../../classes/ui-documents";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {ButtonType} from "../../../shared/enums/button-enum";
import {ProjectDetailsService} from "../../../projects/services/project-details.service";
import {UiBreadCrumbItem} from "../../../shared/classes/ui-bread-crumb-item";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {MatCheckbox} from "@angular/material/checkbox";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {MatBadge} from "@angular/material/badge";
import {
  UploadNewVersionOverlayComponent
} from "../upload-new-version-overlay/upload-new-version-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {VersionComponent} from "../../../shared/components/version/version.component";
import {TabBarComponent} from "../../../shared/components/tab-bar/tab-bar.component";
import {MatTab, MatTabGroup} from "@angular/material/tabs";
import {UiVersions} from "../../../shared/classes/ui-versions";
import {VersionDataService} from "../../services/version-data.service";
import {ChipComponent} from "../../../shared/components/chip/chip.component";
import {ChipType} from "../../../shared/enums/chip-enum";
import {
  SelectionBarComponent
} from '../../../shared/components/selection-bar/selection-bar.component';
import {WsProjectResponseDto} from '@fertirob/fertirob-api';
import {CommentListService} from "../../services/comment-list.service";
import {UiComment} from "../../../shared/classes/ui-comment";
import {CommentComponent} from "../../../shared/components/comment/comment.component";
import {MatFormField, MatSuffix} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {
  CustomPaginatorComponent
} from "../../../shared/components/custom-paginator/custom-paginator.component";
import {
  EmptyStateListComponent
} from "../../../shared/components/empty-state-list/empty-state-list.component";
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDragPreview,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray
} from "@angular/cdk/drag-drop";
import {DocumentTableRowComponent} from "../document-table-row/document-table-row.component";

@Component({
  selector: 'app-page-project-document-list',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    DatePipe,
    HeadlineComponent,
    PageLayoutComponent,
    TranslateModule,
    RouterLink,
    IconButtonComponent,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatIcon,
    MatCheckbox,
    ButtonComponent,
    MatBadge,
    VersionComponent,
    TabBarComponent,
    MatTab,
    MatTabGroup,
    ChipComponent,
    SelectionBarComponent,
    CommentComponent,
    MatFormField,
    MatInput,
    MatSuffix,
    ReactiveFormsModule,
    FormsModule,
    CustomPaginatorComponent,
    AsyncPipe,
    EmptyStateListComponent,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPreview,
    CdkDragPlaceholder,
    DocumentTableRowComponent,
    CdkDropListGroup,
  ],
  templateUrl: './page-project-document-list.component.html',
  styleUrl: './page-project-document-list.component.scss'
})
export class PageProjectDocumentListComponent implements OnInit, OnDestroy {
  public documents: UiDocuments[] = [];
  public folders: UiDocuments[] = [];
  public project$: Observable<WsProjectResponseDto | undefined> = this._projectDetailsService.getProject();
  public isLoading$: Observable<boolean> = this._documentListService.isLoading;
  public versions: UiVersions[] = [];
  public comments: UiComment[] = [];
  private _notifier = new Subject<void>();
  public breadCrumbItems: UiBreadCrumbItem[] = [];
  public selectionMode: boolean = false;
  public checkedDocuments: string[] = [];
  public showVersions: boolean = false;
  public showComments: boolean = false;
  public selectedCommentId: string = ''
  public value = undefined;
  public isDragged: boolean = false;

  private _dragCounter = 0;

  constructor(private _route: ActivatedRoute, private _documentListService: DocumentListService, private _versionDataService: VersionDataService, private _commentListService: CommentListService, private _projectDetailsService: ProjectDetailsService, private _translate: TranslateService, private _dialog: MatDialog) {
  }

  public receiveVersionChange(value: boolean): void {
    this.showVersions = value;
  }

  public receiveCommentChange(value: boolean): void {
    this.showComments = value;
  }

  get pageSize(): number {
    return this._documentListService.getPageSize;
  }

  get currentPage(): number {
    return this._documentListService.getCurrentPage;
  }

  get totalCount(): number {
    return this._documentListService.getTotalCount;
  }

  ngOnInit(): void {
    this._documentListService.getDocumentList().pipe(takeUntil(this._notifier)).subscribe((result) => {
      if (result) {
        this.documents = []
        this.folders = []
        result.forEach((element: UiDocuments) => {
          if (element.isFile) {
            this.documents.push(element);
          } else {
            this.folders.push(element);
          }
        })
      }
    });
    this.getVersions();
    this.getComments();
    this._route.params.subscribe(params => {
      if (params['id']) {
        this._projectDetailsService.fetchProject(params['id']);
      }
    });
    this.project$.pipe(takeUntil(this._notifier)).subscribe(project => {
      this._translate.get(['documents.documents']).subscribe((translations => {
        this.breadCrumbItems = [
          {
            label: translations['documents.documents'],
            route: ['/', RouteConstants.routeDocuments]
          },
          {label: project?.name},
        ];
      }))
    })
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  public getVersions(): void {
    this._versionDataService.getVersions().pipe(takeUntil(this._notifier)).subscribe((result) => {
      if (result) {
        this.versions = result;
      }
    })
  }

  public getComments(): void {
    this._commentListService.getComments().pipe(takeUntil(this._notifier)).subscribe((result) => {
      if (result) {
        this.comments = result;
      }
    })
  }

  public onCheckboxCheck(checked: boolean, documentId: string | undefined): void {
    if (!documentId) return;
    if (checked) {
      this.checkedDocuments.push(documentId);
    } else {
      this.checkedDocuments = this.checkedDocuments.filter(doc => doc !== documentId);
    }
  }

  public onCheckAll(checked: boolean): void {
    this.checkedDocuments = [];
    if (checked) {
      this.documents?.forEach((param) => {
        if (param.id) {
          this.checkedDocuments.push(param.id);
        }
      })
      this.folders?.forEach((param) => {
        if (param.id) {
          this.checkedDocuments.push(param.id)
        }
      })
    }
  }

  public isChecked(documentId?: string): boolean {
    if (!documentId) return false;
    return this.checkedDocuments.includes(documentId);
  }

  public areAllChecked(): boolean {
    return this.checkedDocuments.length === this.documents?.length + this.folders?.length;
  }

  public areSomeChecked(): boolean {
    return this.checkedDocuments.length > 0 && !this.areAllChecked();
  }

  public toggleSelectionMode(mode: boolean) {
    this.selectionMode = mode;
    if (!this.selectionMode) {
      this.checkedDocuments = [];
    }
  }

  public onDownload(): void {
    //TODO download selected items
  }

  public onDelete(): void {
    //TODO delete selected items
    this._documentListService.deleteDocument()
  }

  protected readonly RouteConstants = RouteConstants;
  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;

  showUploadOverlay(): void {
    const dialogRef = this._dialog.open(UploadNewVersionOverlayComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.getDocuments();
      }
    });
  }

  onShowVersions() {
    this.showVersions = !this.showVersions;
    this.showComments = false;
  }

  protected readonly ChipType = ChipType;

  onShowComments() {
    this.showComments = !this.showComments;
    this.showVersions = false;
  }


  setSelectedCommentId($event: string) {
    this.selectedCommentId = $event
  }

  onChangePage(page: number) {
    this._documentListService.setCurrentPage(page);
  }

  get isEmpty(): boolean {
    return this.documents.length === 0 && this.folders.length === 0;
  }



  showSubDocuments(clickedDocumentId: string | undefined): void {
    const clickedDocument = this.folders.find(doc => doc.id === clickedDocumentId);
    console.log(clickedDocument)

    if (clickedDocument) {
      this.documents = clickedDocument.subDocuments?.filter(doc => doc.isFile) as UiDocuments[];
      this.folders = clickedDocument.subDocuments?.filter(doc => !doc.isFile) as UiDocuments[];
    }
  }

  drop(event: CdkDragDrop<UiDocuments[], any>, folderId: string | undefined) {
    console.log(event.previousContainer.data[event.previousIndex].id)
    console.log(folderId)
  }

  updateDocuments(event: any) {
    this.isDragged = false
    this._dragCounter = 0;
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    this._dragCounter++;
    this.isDragged = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this._dragCounter--;
    if (this._dragCounter === 0) {
      this.isDragged = false;
    }
  }
}

