<app-page-layout>
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    @if (project$ | async; as project) {
      <app-headline [hideDivider]="true" [title]="project?.name"></app-headline>
      <app-tab-bar [states]="statesList" (activeStateChange)="onChangeActiveState($event)"></app-tab-bar>
      @if (isGeneralTabActive) {
        <app-project-general [project]="project"></app-project-general>
      } @else if (isInputsTabActive) {
        <app-project-inputs [project]="project"></app-project-inputs>
      }
    }
  </ng-container>
</app-page-layout>
