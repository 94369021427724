<div class="bread-crumb-navigation">
  @for (item of items; track item; let first = $first; let index = $index) {
    @if (first || index >= (items.length - (shownElements - 1))) {
      @if (item.route) {
        <a [routerLink]="item.route">{{ item.label }}</a>
      } @else {
        <p>{{ item.label }}</p>
      }
      @if (items.length > shownElements && first) {
        <p>{{ 'default.ellipsis' | translate }}</p>
      }
    }
  }
</div>
