import {Component} from '@angular/core';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {InputWrapperComponent} from '../../../shared/components/input/input-wrapper/input-wrapper.component';
import {MatFormField, MatSuffix} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatOption} from '@angular/material/core';
import {MatSelect} from '@angular/material/select';
import {OverlayComponent} from '../../../shared/components/overlay/overlay.component';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonType} from '../../../shared/enums/button-enum';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {filter, take, takeUntil, tap} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {PdfViewerComponent} from '../../../shared/components/pdf-viewer/pdf-viewer.component';
import {AssemblyTableRowComponent} from '../../../modules/components/assembly-table-row/assembly-table-row.component';
import {TableInfoRowComponent} from '../../../shared/components/table-info-row/table-info-row.component';
import {DragAndDropComponent} from '../../../shared/components/drag-and-drop/drag-and-drop.component';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {PartTableRowComponent} from '../../../modules/components/part-table-row/part-table-row.component';
import {NavigationStart, Router} from '@angular/router';
import {WsComponentResponseDto, WsComponentResponseDtoTypeEnum} from '@fertirob/fertirob-api';
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';

@Component({
  selector: 'app-component-details-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    IconButtonComponent,
    InputWrapperComponent,
    MatFormField,
    MatIcon,
    MatOption,
    MatSelect,
    MatSuffix,
    OverlayComponent,
    ReactiveFormsModule,
    TranslateModule,
    PdfViewerComponent,
    TableInfoRowComponent,
    DragAndDropComponent,
    HeadlineComponent,
    PartTableRowComponent,
    AssemblyTableRowComponent,
    EmptyStateListComponent
  ],
  templateUrl: './component-details-overlay.component.html',
  styleUrl: './component-details-overlay.component.scss'
})
export class ComponentDetailsOverlayComponent {
  public component: WsComponentResponseDto | undefined = undefined;

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  constructor(public dialogRef: MatDialogRef<ComponentDetailsOverlayComponent>,
              private _router: Router
  ) {
    this._router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      tap(() => this.dialogRef.close()),
      take(1),
    ).subscribe();
  }

  onHideOverlay = (): void => {
    this.dialogRef.close(false);
  }

  onSubmit = (): void => {
    this.dialogRef.close(true);
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }
}
