<app-overlay>
  <ng-container header>
    <app-headline [title]="'documents.upload.upload' | translate">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="onHideOverlay()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <form [formGroup]="uploadForm">
      <app-input-wrapper [id]="'changes'" [label]="'documents.upload.changes' | translate" [control]="uploadForm.controls['changes']">
        <textarea id="changes" formControlName="changes"
               [placeholder]="'default.inputPlaceholder' | translate"></textarea>
      </app-input-wrapper>
    </form>
    <p>
      {{ 'documents.upload.uploadThis' | translate }}
    </p>
      @if (selectedFiles.length > 0) {
          <div class="overlay-list">
              @for (file of selectedFiles; track file) {
                  <ul>
                      <li>
                          <i class="material-icons">{{ IconEnum.DOCUMENTS }}</i>
                          <p class="file-name">{{ file.name }}</p>
                          <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="removeFile(file)"></app-icon-button>
                      </li>
                  </ul>
              }
          </div>
      }
      <div class="upload-button">
          <app-button (click)="selectFileInput.click()"
                      [isSmall]="true" [type]="ButtonType.SECONDARY"
                      [label]="'documents.upload.search' | translate"></app-button>
          <input #selectFileInput class="hide" type="file" multiple="multiple" (change)="onFileSelected($event)">
      </div>
  </ng-container>
  <ng-container buttons>
          @if (selectedFiles.length > 0){
              <app-button [label]="'documents.upload.upload' | translate" [isSmall]="true" [isDisabled]="uploadForm.invalid" (click)="onHideOverlay()"></app-button>
          }
  </ng-container>
</app-overlay>
