<app-page-layout [noButtons]="true">
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    <app-headline [title]="'Papierkorb'" [hideDivider]="true"></app-headline>
    <app-info-banner [message]="'documents.trash.info' | translate: {days: storageTime}">
      <app-icon-button [iconName]="IconEnum.SETTINGS" (click)="openTimeSettings()" [type]="ButtonType.PLAIN"></app-icon-button>
    </app-info-banner>
    @if (!(isEmpty |async) && !(isLoading$ | async)) {
      <div class="main-content">
        <div class="table-wrapper">
          <table>
            <tbody>
            <tr>
              <th>{{ 'default.name' | translate }}</th>
              <th>{{ 'documents.trash.accesses' | translate }}</th>
              <th>{{ 'documents.trash.moved' | translate }}</th>
              <th>{{ 'documents.trash.size' | translate }}</th>
              <th>{{ 'documents.trash.origin' | translate }}</th>
              <th></th>
            </tr>
              @for (document of (trashList$ | async); track document) {
                <tr>
                  <td>
                    <div class="flex-content-wrapper">
                      <i
                        class="material-icons">{{ document.isFile ? IconEnum.DOCUMENTS : IconEnum.FOLDER }}</i>{{ document.name }}
                    </div>
                  </td>
                  <td>{{ document.responsibleUser }}</td>
                  <td>{{ document.deletedDate | date }}</td>
                  <td>{{ document.size + 'KB' }}</td>
                  <td class="file-origin-cell">
                    <div class="flex-content-wrapper">
                      <i class="material-icons">{{ IconEnum.FOLDER }}</i>
                      <p class="file-origin">{{ document.origin }}</p>
                      <i class="material-icons info-trigger-button"
                         [matMenuTriggerFor]="origin">{{ IconEnum.INFO }}</i>

                      <mat-menu #origin=matMenu>
                        <p
                          class="custom-menu-item info-headline">{{ 'documents.trash.originalPath' | translate }}
                          :</p>
                        <p class="custom-menu-item info-text">{{ document.origin }}</p>
                      </mat-menu>
                    </div>
                  </td>
                  <td>
                    <div class="flex-content-wrapper">
                      <app-icon-button [iconName]="IconEnum.MORE" [type]="ButtonType.PLAIN"
                                       [matMenuTriggerFor]="menu"></app-icon-button>

                      <mat-menu #menu=matMenu>
                        <button mat-menu-item (click)="restoreDoc()">
                          <mat-icon class="material-icons">{{ IconEnum.HISTORY }}</mat-icon>
                          {{ 'documents.trash.restore' | translate }}
                        </button>
                        <button mat-menu-item (click)="onShowDeleteOverlay()">
                          <mat-icon class="material-icons">{{ IconEnum.DELETE }}</mat-icon>
                          {{ 'documents.trash.deletePermanently' | translate }}
                        </button>
                      </mat-menu>
                    </div>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <app-custom-paginator (pageChanged)="onChangePage($event)" [totalElements]="totalCount"
                            [currentPage]="currentPage" [pageSize]="pageSize">
      </app-custom-paginator>
    }
    @if ((isEmpty | async) && !(isLoading$ | async)) {
      <app-empty-state-list
        [emptyStateText]="'documents.trash.trashEmptyState' | translate"></app-empty-state-list>
    }
  </ng-container>
</app-page-layout>
