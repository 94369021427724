import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IconEnum} from '../../enums/icon-enum';
import {IconButtonComponent} from '../icon-button/icon-button.component';
import {ButtonType} from '../../enums/button-enum';

@Component({
  selector: 'app-custom-paginator',
  standalone: true,
  imports: [
    IconButtonComponent
  ],
  templateUrl: './custom-paginator.component.html',
  styleUrl: './custom-paginator.component.scss'
})
export class CustomPaginatorComponent implements OnInit, OnChanges {
  @Input() currentPage = 0;
  @Input() pageSize = 10;
  @Input() totalElements!: number;

  @Output() pageChanged = new EventEmitter<number>();

  public pageNumbers: number[] = [];
  public totalPages: number = 0;
  public amountOfPageIndicatorsShown: number = 3;

  constructor() {
  }

  ngOnInit(): void {
    this.createPagesArray();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createPagesArray();
  }

  onClickPageNumber(pageNumber: number): void {
    if (pageNumber < this.totalPages && pageNumber !== this.currentPage) {
      this.currentPage = pageNumber;
      this.pageChanged.emit(this.currentPage);
    }
  }

  private createPagesArray(): void {
    if (this.totalElements !== undefined) {
      this.totalPages = Math.ceil(this.totalElements / this.pageSize);
      if (this.totalPages < this.amountOfPageIndicatorsShown) {
        this.amountOfPageIndicatorsShown = this.totalPages;
      }
      let currentPageOffset = Math.floor(this.amountOfPageIndicatorsShown / 2);
      for (let i = 0; i < this.amountOfPageIndicatorsShown; i++) {
        if (this.currentPage < currentPageOffset) {
          //Start
          this.pageNumbers[i] = i;
        } else if ((this.currentPage + 1) >= (this.totalPages - currentPageOffset)) {
          //End
          this.pageNumbers[i] = this.totalPages - (this.amountOfPageIndicatorsShown - i);
        } else {
          //Middle
          this.pageNumbers[i] = this.currentPage - (currentPageOffset - i);
        }
      }
    }
  }

  get shownIndicatorsIncludeFirstPage(): boolean {
    return this.pageNumbers.includes(0);
  }

  get shownIndicatorsIncludeLastPage(): boolean {
    return this.pageNumbers.includes(this.totalPages - 1);
  }

  get onFirstPage(): boolean {
    return this.currentPage === 0
  }

  get onLastPage(): boolean {
    return this.currentPage === this.totalPages - 1
  }

  protected readonly IconEnum = IconEnum;
  protected readonly ButtonType = ButtonType;
}
