import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-headline',
  standalone: true,
  imports: [],
  templateUrl: './headline.component.html',
  styleUrl: './headline.component.scss'
})
export class HeadlineComponent {
  @Input() title?: string;
  @Input() hideDivider: boolean = false;

}
