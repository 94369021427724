<app-page-layout [noButtons]="true">
  <ng-container layout-top>
    <app-bread-crumb-navigation [items]="breadCrumbItems"></app-bread-crumb-navigation>
  </ng-container>
  <ng-container layout-main>
    @if (component$ | async; as component) {
      <app-headline [hideDivider]="true" [title]="component?.name"></app-headline>

      @if (component.module?.readonly) {
        <app-info-banner [message]="'modules.lockedComponent' | translate"></app-info-banner>
      }
      <app-tab-bar [states]="statesList"
                   (activeStateChange)="onChangeActiveState($event)"></app-tab-bar>
      @if (activeState) {
        @if (isGeneralTabActive) {
          <app-component-details-general [componentInput]="component"
                                         [isReadOnlyMode]="component.readonly??false"></app-component-details-general>
        } @else if (isParametersTabActive) {
          <app-component-details-parameters [component]="component"
                                            [isReadOnlyMode]="component.readonly??false"></app-component-details-parameters>
        }
      }
    }
  </ng-container>
</app-page-layout>
