import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonType} from '../../enums/button-enum';
import {IconEnum} from '../../enums/icon-enum';
import {IconButtonComponent} from '../icon-button/icon-button.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-selection-bar',
  standalone: true,
  imports: [
    IconButtonComponent,
    TranslateModule
  ],
  templateUrl: './selection-bar.component.html',
  styleUrl: './selection-bar.component.scss'
})
export class SelectionBarComponent {
  @Input() selectedAmount: number = 0;
  @Output() onChangeSelectionMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onTriggerDownload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onTriggerDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  public toggleSelectionMode() {
    this.onChangeSelectionMode.emit(true);
  }

  public onDownload() {
    this.onTriggerDownload.emit(true);
  }

  public onDelete() {
    this.onTriggerDelete.emit(true);
  }

  get noSelection(): boolean {
    return this.selectedAmount === 0;
  }
}
