<div class="navigation-item-wrapper">
  <a #navItem="routerLinkActive" [routerLink]="[route]" routerLinkActive="active"
     class="navigation-item"
     [class.is-sub-item]="isSubItem">
    <span class="material-icons">{{ iconName }}</span>
    <span>{{ label ?? '' | translate }}</span>
  </a>
</div>
@if (navItem.isActive && subItems) {
  @for (item of subItems; track item) {
    <app-navigation-item [isSubItem]="true" [label]="item.label"
                         [route]="item.route" [iconName]="IconEnum.TRASH"></app-navigation-item>
  }
}
