import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MainNavigationComponent} from './shared/components/main-navigation/main-navigation.component';
import {HeaderComponent} from './shared/components/header/header.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MainNavigationComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'fertirob-frontend';

  constructor(private _translate: TranslateService) {
    this._translate.use('de');
  }

  ngOnInit(): void {
  }
}
