export const environment = {
  production: false,
  baseUrl: 'http://localhost:4200',
  apiUrl: 'https://api.fertirob.letsdev.de',
  restEndpoint: '/rest/v1',
  version: require('../../package.json').version,
  ssoBaseUrl: 'http://localhost:9999/single-sign-on',
  ssoRequireHttps: false,
  clientId: 'fertirobFrontend',
  clientSecret: 'CLIENT_SECRET'
};

