import {Component, Input} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-table-info-row',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './table-info-row.component.html',
  styleUrl: './table-info-row.component.scss'
})
export class TableInfoRowComponent {
  @Input() element?: string
}
