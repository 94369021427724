<div class="input-wrapper" [class.no-margin]="!label" [class.invalid]="shouldPrintError" [class.error]="shouldPrintError"
     [class.stacked]="stacked">
  @if (label) {
    <label for="{{id}}">
      {{ label }}{{ requiredMarker }}
    </label>
  }
  <div class="input-content">
    <ng-content></ng-content>
  </div>
  @for (e of renderErrors; track e.error) {
    <p class="error-message error wide">{{ e.message | async }}</p>
  }
</div>
