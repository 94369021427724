import {Component, Input} from '@angular/core';
import {RouteConstants} from '../../constants/route-constants';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {IconEnum} from '../../enums/icon-enum';
import {UiNavigationItem} from "../../classes/ui-navigation-item";

@Component({
  selector: 'app-navigation-item',
  standalone: true,
  imports: [
    RouterLinkActive,
    TranslateModule,
    RouterLink
  ],
  templateUrl: './navigation-item.component.html',
  styleUrl: './navigation-item.component.scss'
})
export class NavigationItemComponent {
  @Input() iconName?: IconEnum = IconEnum.ADD;
  @Input() label?: string;
  @Input() route?: string;
  @Input() subItems?: UiNavigationItem[];
  @Input() isSubItem?: boolean;
  protected readonly RouteConstants = RouteConstants;
  protected readonly IconEnum = IconEnum;
}
