{
  "name": "fertirob-frontend",
  "version": "0.2.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve:ssr:fertirob-frontend": "node dist/fertirob-frontend/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "18.2.2",
    "@angular/common": "18.2.2",
    "@angular/compiler": "18.2.2",
    "@angular/core": "18.2.2",
    "@angular/forms": "18.2.2",
    "@angular/material": "18.2.3",
    "@angular/platform-browser": "18.2.2",
    "@angular/platform-browser-dynamic": "18.2.2",
    "@angular/router": "18.2.2",
    "@fertirob/fertirob-api": "0.4.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "ng2-pdfjs-viewer": "18.0.0",
    "ngx-tagify": "^16.1.2",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.2.3",
    "@angular/cli": "18.2.3",
    "@angular/compiler-cli": "18.2.2",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.3.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
