import {Component, OnDestroy, OnInit} from '@angular/core';
import {BreadCrumbNavigationComponent} from '../../../shared/components/bread-crumb-navigation/bread-crumb-navigation.component';
import {ButtonComponent} from '../../../shared/components/button/button.component';
import {ChipComponent} from '../../../shared/components/chip/chip.component';
import {CommentComponent} from '../../../shared/components/comment/comment.component';
import {DragAndDropComponent} from '../../../shared/components/drag-and-drop/drag-and-drop.component';
import {HeadlineComponent} from '../../../shared/components/headline/headline.component';
import {MatTab, MatTabGroup} from '@angular/material/tabs';
import {PageLayoutComponent} from '../../../shared/components/page-layout/page-layout.component';
import {StepBarComponent} from '../../../shared/components/step-bar/step-bar.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ButtonType} from '../../../shared/enums/button-enum';
import {AssemblyTableRowComponent} from '../assembly-table-row/assembly-table-row.component';
import {PartTableRowComponent} from '../part-table-row/part-table-row.component';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {ModuleTableRowComponent} from '../module-table-row/module-table-row.component';
import {EmptyStateListComponent} from '../../../shared/components/empty-state-list/empty-state-list.component';
import {ModuleListService} from '../../services/module-list.service';
import {MatDialog} from '@angular/material/dialog';
import {AddModuleOverlayComponent} from '../add-module-overlay/add-module-overlay.component';
import {map, Observable, Subject, takeUntil} from 'rxjs';
import {FeedbackType} from '../../../shared/enums/feedback-type';
import {FeedbackService} from '../../../shared/services/feedback.service';
import {UiBreadCrumbItem} from '../../../shared/classes/ui-bread-crumb-item';
import {WsModuleRequestDto, WsModuleResponseDto} from '@fertirob/fertirob-api';
import {AsyncPipe} from '@angular/common';
import {CustomPaginatorComponent} from '../../../shared/components/custom-paginator/custom-paginator.component';
import {SpinnerComponent} from '../../../shared/components/spinner/spinner.component';

@Component({
  selector: 'app-page-module-list',
  standalone: true,
  imports: [
    BreadCrumbNavigationComponent,
    ButtonComponent,
    ChipComponent,
    CommentComponent,
    DragAndDropComponent,
    HeadlineComponent,
    MatTab,
    MatTabGroup,
    PageLayoutComponent,
    StepBarComponent,
    TranslateModule,
    AssemblyTableRowComponent,
    PartTableRowComponent,
    ModuleTableRowComponent,
    EmptyStateListComponent,
    AsyncPipe,
    CustomPaginatorComponent,
    SpinnerComponent
  ],
  templateUrl: './page-module-list.component.html',
  styleUrl: './page-module-list.component.scss'
})
export class PageModuleListComponent implements OnInit, OnDestroy {

  public modules$: Observable<WsModuleResponseDto[]> = this._moduleListService.getModuleList();
  public isLoading$: Observable<boolean> = this._moduleListService.isLoading;

  public breadCrumbItems: UiBreadCrumbItem[] = [];
  private _notifier = new Subject<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  constructor(private _moduleListService: ModuleListService,
              private _dialog: MatDialog,
              private _translate: TranslateService) {
  }

  get pageSize(): number {
    return this._moduleListService.getPageSize;
  }

  get currentPage(): number {
    return this._moduleListService.getCurrentPage;
  }

  get totalCount(): number {
    return this._moduleListService.getTotalCount;
  }

  ngOnInit(): void {
    this._moduleListService.setPageSize(10);
    this._translate.get(['modules.modules']).subscribe((translations => {
      this.breadCrumbItems = [{label: translations['modules.modules']}]
    }))
  }

  ngOnDestroy() {
    this._notifier.next();
    this._notifier.complete();
  }

  get isEmpty(): Observable<boolean> {
    return this.modules$.pipe(
      map(modules => modules.length === 0)
    );
  }

  public onShowAddModuleOverlay(): void {
    const dialogRef = this._dialog.open(AddModuleOverlayComponent);
    dialogRef.afterClosed().subscribe((result: WsModuleRequestDto | undefined) => {
      if (result) {
        this._moduleListService.saveModule(result)
      }
    });
  }

  public onTriggerImport(files: any): void {
    //TODO Call service to upload file
  }

  public onChangePage(page: number): void {
    this._moduleListService.setCurrentPage(page);
  }
}
