import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TagData, TagifyModule, TagifySettings} from 'ngx-tagify';
import {FormsModule} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-tagify-input',
  standalone: true,
  imports: [
    TagifyModule,
    FormsModule
  ],
  templateUrl: './tagify-input.component.html',
  styleUrl: './tagify-input.component.scss'
})
export class TagifyInputComponent implements OnInit{
  @Input() parameterList: string[] = [];
  @Input() ruleValueToEdit: string = '';
  @Input() set resetRule(value: boolean) {
    if (this.tagify) {
      this.tagify.tagify.DOM.input.innerHTML = '';
    }
  };
  @Output() onRuleChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('tagify') tagify?: any;

  public settings: TagifySettings = {};
  public whiteList$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  public rule: TagData[] = [];

  constructor(private _translate: TranslateService) {
  }

  ngOnInit() {
    this.setupTagify();
  }

  private setupTagify(): void {
    this.whiteList$.next(this.parameterList);
    this.settings = {
      mode: 'mix',
      pattern: /@/,
      dropdown: {
        enabled: 0,
        includeSelectedTags: true
      },
      placeholder: '',
      duplicates: true,
      callbacks: {
        add: (e) => {
          this.onAddTag(e);
        }
      }
    };
    this._translate.get(['default.inputPlaceholder']).subscribe((translations => {
      this.settings.placeholder = translations['default.inputPlaceholder'];
    }))
  }

  public onAddTag(e: any): void {
    let isInWhiteList = this.whiteList$.value.includes(e.detail.data.value);
    if (!isInWhiteList) {
      this.tagify.tagify.removeTag(e.detail.data.value);
    }
  }

  public onInput(): void {
    this.onRuleChange.emit(this.tagify);
  }
}

