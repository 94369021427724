<div class="headline-wrapper"
     [class.hideDivider]="hideDivider">
  <div class="headline-container">
    <span class="title">{{ title }}</span>
    <div class="headline-button-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
  <hr>
</div>

