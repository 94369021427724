import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {FeedbackType} from '../../enums/feedback-type';
import {UiSnackbarData} from '../../classes/ui-snackbar-data';

@Component({
  selector: 'app-custom-snack-bar',
  standalone: true,
  imports: [],
  templateUrl: './custom-snack-bar.component.html',
  styleUrl: './custom-snack-bar.component.scss'
})
export class CustomSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: UiSnackbarData) {
  }

  protected readonly FeedbackType = FeedbackType;
}
