import {Component, Input, OnInit} from '@angular/core';
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  InputWrapperComponent
} from "../../../shared/components/input/input-wrapper/input-wrapper.component";
import {
  DragAndDropComponent
} from "../../../shared/components/drag-and-drop/drag-and-drop.component";
import {RouterLink} from "@angular/router";
import {ButtonType} from "../../../shared/enums/button-enum";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {AssemblyTableRowComponent} from "../assembly-table-row/assembly-table-row.component";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {
  EmptyStateListComponent
} from "../../../shared/components/empty-state-list/empty-state-list.component";
import {MatFormField, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {
  TableInfoRowComponent
} from "../../../shared/components/table-info-row/table-info-row.component";
import {TranslateModule} from "@ngx-translate/core";
import {PartTableRowComponent} from "../part-table-row/part-table-row.component";
import {
  AddComponentOverlayComponent
} from '../add-component-overlay/add-component-overlay.component';
import {MatDialog} from '@angular/material/dialog';
import {RouteConstants} from '../../../shared/constants/route-constants';
import {map, Observable} from "rxjs";
import {
  LeavePageOverlayComponent
} from "../../../shared/components/leave-page-overlay/leave-page-overlay.component";
import {
  FilterOptionWrapperComponent
} from '../../../shared/components/filter-option-wrapper/filter-option-wrapper.component';
import {
  WsComponentRequestDto,
  WsComponentRequestDtoTypeEnum,
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum,
  WsModuleResponseDto
} from '@fertirob/fertirob-api';
import {ComponentDetailsService} from '../../services/component-details.service';
import {ConverterService} from "../../../shared/services/converter.service";

@Component({
  selector: 'app-component-details-general',
  standalone: true,
  imports: [
    HeadlineComponent,
    ReactiveFormsModule,
    InputWrapperComponent,
    DragAndDropComponent,
    AssemblyTableRowComponent,
    ButtonComponent,
    EmptyStateListComponent,
    MatFormField,
    MatIcon,
    MatInput,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSuffix,
    TableInfoRowComponent,
    TranslateModule,
    PartTableRowComponent,
    FilterOptionWrapperComponent,
    RouterLink
  ],
  templateUrl: './component-details-general.component.html',
  styleUrl: './component-details-general.component.scss'
})
export class ComponentDetailsGeneralComponent implements OnInit {
  @Input() set componentInput(component: WsComponentResponseDto | undefined | null) {
    if (component) {
      this.component = component;
    }
    this.setUpForm();
  };
  @Input() isReadOnlyMode: boolean = false;

  public component?: WsComponentResponseDto;
  public componentForm: FormGroup = new FormGroup({});
  public filterOptions: string[] = [];

  constructor(private _componentDetailsService: ComponentDetailsService,
              private _fb: FormBuilder,
              private _dialog: MatDialog,
              private _converterService: ConverterService
  ) {
  }

  ngOnInit(): void {
    this.componentForm = this._fb.group({
      name: [{value: '', disabled: this.isReadOnlyMode}, Validators.required],
      description: [{value: '', disabled: this.isReadOnlyMode}]
    })
    this.getFilterOptions();
    this.setUpForm();
  }

  public getFilterOptions(): void {
    this.filterOptions = ['Alle', 'Einer', 'Beide'];
  }

  public setUpForm(): void {
    this.componentForm.patchValue({
      name: this.component?.name,
      description: this.component?.description
    })
  }

  get isEmpty(): boolean {
    return !this.component?.subComponents?.length;
  }

  public onShowAddComponentOverlay(): void {
    const dialogRef = this._dialog.open(AddComponentOverlayComponent);
    dialogRef.afterClosed().subscribe((result: WsComponentRequestDto) => {
      if (result && this.component?.id) {
          this._componentDetailsService.createComponent(this.component.id, result)
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.componentForm.dirty) {
      const dialogRef = this._dialog.open(LeavePageOverlayComponent);
      return dialogRef.afterClosed().pipe(map(result => result === true));
    }
    return true;
  }

  public onSearchTermChange(searchTerm: string): void {
    //TODO call service
  }

  public onFilterOptionChange(filterOption: string): void {
    //TODO call service
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;
  protected readonly RouteConstants = RouteConstants;

  onSaveComponent(): void {
    this.componentForm.markAsPristine();
    let saveComponentRequest: WsComponentRequestDto = {
      parentId: this.component?.parentComponent?.id ?? this.component?.module?.id ?? '',
      type: this._converterService.convertComponentResponseEnumsToRequest(this.component?.type),
      name: this.componentForm.value.name,
      description: this.componentForm.value.description
    }
    this._componentDetailsService.updateComponent(this.component?.id!, saveComponentRequest)
  }
}
