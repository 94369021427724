<div class="drag-and-drop">
  @if (file) {
    @if (file.type === 'application/pdf') {
      <div class="pdf-container">
        <app-pdf-viewer [pdfFileInput]="file"></app-pdf-viewer>
        <div class="icon-container">
          @if (!disableDelete) {
            <app-icon-button [type]="ButtonType.PREVIEW" [isSmall]="true" [iconName]="IconEnum.DELETE"
                             (click)="deletePdfFileInput()"></app-icon-button>
          }
        </div>
      </div>
    } @else {
      <div class="image-container">
        <div class="file-input">
          <input [attr.accept]="acceptedFileTypes" type="file">
          <img class="preview" *ngIf="imagePreview" [src]="imagePreview" alt="{{'pdfViewer.preview' | translate}}">
        </div>
        <div class="icon-container">
          @if (!disableDelete) {
            <app-icon-button [type]="ButtonType.PREVIEW" [isSmall]="true" [iconName]="IconEnum.DELETE"
                             (click)="deletePdfFileInput()"></app-icon-button>
          }
          <app-icon-button [type]="ButtonType.PREVIEW" class="image-icon" [isSmall]="true"
                           [iconName]="IconEnum.EXPAND" (click)="openImgInNewTab()"></app-icon-button>

        </div>
      </div>
    }
  } @else if (!file) {
    <div class="file-input" [class.isDisabled]="isDisabled">
      <input [disabled]="isDisabled" [attr.accept]="acceptedFileTypes" type="file">
      <div class="drop-area">
        <i class="material-icons">{{ IconEnum.ADD_PHOTO }}</i>
        <p class="file-input-text">{{ 'pdfViewer.dropFile' |translate }}</p>
        <app-button [label]="'pdfViewer.search' | translate"
                    [type]="ButtonType.SECONDARY"></app-button>
      </div>
    </div>
  }
</div>

