import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {InformationItemStateEnum} from '../../../enums/information-item-state-enum';
import {AsyncPipe} from '@angular/common';

@Component({
  selector: 'app-input-wrapper',
  standalone: true,
  imports: [
    AsyncPipe
  ],
  templateUrl: './input-wrapper.component.html',
  styleUrl: './input-wrapper.component.scss'
})
export class InputWrapperComponent {
  @Input() label?: string;
  @Input() hint?: string;
  @Input() id?: string;
  @Input() stacked?: boolean;
  @Input() successMessage?: string;
  @Input({required: true}) control!: AbstractControl;
  options = {
    requiredMarker: '*'
  }

  constructor(private _translate: TranslateService) {

  }

  get requiredMarker(): string {
    if (!this.control.hasValidator(Validators.required)) return ''
    return this.options.requiredMarker ?? ''
  }

  get hasErrors(): boolean {
    return this.control?.invalid
  }

  get shouldPrintError(): boolean {
    return this.hasErrors && this.control.touched
  }

  get renderErrors(): RenderedError[] {
    if (!this.shouldPrintError) {
      return []
    }

    const result: RenderedError[] = []
    const errors: ValidationErrors | null | undefined = this.control.errors;

    if (errors) {
      Object.keys(errors).forEach(keyError => {

        result.push({
          'error': keyError,
          'message': this.getMessage(keyError, errors),
          'value': errors![keyError]
        });
      });
    }

    return result
  }

  getMessage(keyError: string, errors: ValidationErrors): Observable<string> {
    switch (keyError) {
      case 'required':
        return this._translate.get('error.required');
      case 'max':
        return this._translate.get('error.max', {max: errors![keyError].max});
      case 'min':
        return this._translate.get('error.min', {min: errors![keyError].min});
      case 'minlength':
        return this._translate.get('error.minLength', {min: errors![keyError].requiredLength});
      case 'maxlength':
        return this._translate.get('error.maxLength', {max: errors![keyError].requiredLength});
      case 'pattern':
        return this._translate.get('error.pattern', {pattern: errors![keyError].requiredPattern});
      case 'maxFileSize':
        return this._translate.get('error.maxFileSize', {max: errors![keyError].maxSize});
      case 'passwordStrength':
        return this._translate.get('error.passwordStrength');
      case 'passwordsMatch':
        return this._translate.get('error.passwordsMatch');
      case 'oldPasswordsMatch':
        return this._translate.get('error.oldPasswordsMatch');
      default:
        return this._translate.get('error.default');
    }
  }

  protected readonly InformationItemStateEnum = InformationItemStateEnum;
}

interface RenderedError {
  error: string;
  message: any;
  value: any;
}

export function isSubmitAllowed(form: FormGroup): boolean {
  if (form.valid) {
    return true
  }

  form.markAllAsTouched();
  window.scroll(0, 0)
  return false
}
