import {Component} from '@angular/core';
import {OverlayComponent} from '../overlay/overlay.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ButtonComponent} from "../button/button.component";
import {ButtonType} from "../../enums/button-enum";
import {HeadlineComponent} from "../headline/headline.component";
import {IconEnum} from '../../enums/icon-enum';
import {IconButtonComponent} from '../icon-button/icon-button.component';

@Component({
  selector: 'app-leave-page-overlay',
  standalone: true,
    imports: [
        OverlayComponent,
        TranslateModule,
        ButtonComponent,
        HeadlineComponent,
        IconButtonComponent
    ],
  templateUrl: './leave-page-overlay.component.html',
  styleUrl: './leave-page-overlay.component.scss'
})
export class LeavePageOverlayComponent {
  constructor(public dialogRef: MatDialogRef<LeavePageOverlayComponent>) {
  }

  onHideOverlay = (): void => {
    this.dialogRef.close(false);
  }

  onLeavePage(): void {
    this.dialogRef.close(true);
  }

  protected readonly ButtonType = ButtonType;
    protected readonly IconEnum = IconEnum;
}
