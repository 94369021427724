import {Component, Input, OnInit} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-info-banner',
  standalone: true,
  imports: [
    TranslateModule
  ],
  templateUrl: './info-banner.component.html',
  styleUrl: './info-banner.component.scss'
})
export class InfoBannerComponent {
  @Input() message: string | undefined;

}
