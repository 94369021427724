export enum IconEnum {
  ADD = 'add',
  EDIT = 'edit',
  DASHBOARD = 'dashboard',
  PRODUCTION_MODULE = 'deployed_code',
  PROJECT = 'design_services',
  ORGANISATION = 'account_circle',
  DOCUMENTS = 'description',
  TRASH = 'remove',
  LOG_OUT = 'logout',
  SEARCH = 'manage_search',
  CHECK = 'check',
  EXCLAMATION = 'exclamation',
  PERSON = 'person',
  SEND = 'send',
  DELETE = 'delete',
  EXPAND = "open_in_full",
  ADD_PHOTO = "add_photo_alternate",
  ARROW_DOWN = "keyboard_arrow_down",
  ARROW_UP = "keyboard_arrow_up",
  PART = "memory",
  RULES = "account_tree",
  PARAMETER = "interests",
  DOWNLOAD = "download",
  MORE = "more_vert",
  CHEVRON_RIGHT = "chevron_right",
  CHEVRON_LEFT = "chevron_left",
  MORE_HORIZ = "more_horiz",
  CLOSE = "close",
  EQUAL = "equal",
  ARROW_DROPDOWN = "arrow_drop_down",
  ARROW_DROPUP = "arrow_drop_up",
  FOLDER = "folder",
  COMMENT = "comment",
  HISTORY = "history",
  UPLOAD = "upload",
  SETTINGS = "settings",
  INFO = "info",
  FILE_COPY = "file_copy",
  LOCK = "lock"
}
