<button [class.primary]="type==ButtonType.PRIMARY"
        [class.secondary]="type==ButtonType.SECONDARY"
        [class.preview]="type==ButtonType.PREVIEW"
        [class.plain]="type==ButtonType.PLAIN"
        class="icon-button"
        [class.square]="!label"
        [class.small]="isSmall"
        [disabled]="isDisabled"
        type="button">
    <i class="material-icons">{{ iconName }}</i>
    {{ label }}
</button>
