import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ButtonType} from "../../../shared/enums/button-enum";
import {HeadlineComponent} from "../../../shared/components/headline/headline.component";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {TranslateModule} from "@ngx-translate/core";
import {
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import {
  InputWrapperComponent
} from "../../../shared/components/input/input-wrapper/input-wrapper.component";
import {IconEnum} from "../../../shared/enums/icon-enum";
import {MatFormField, MatPrefix, MatSuffix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";
import {MatInput} from "@angular/material/input";
import {MatOption} from "@angular/material/core";
import {MatSelect} from "@angular/material/select";
import {AsyncPipe, DatePipe} from "@angular/common";
import {
  EmptyStateListComponent
} from "../../../shared/components/empty-state-list/empty-state-list.component";
import {IconButtonComponent} from "../../../shared/components/icon-button/icon-button.component";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatSort, MatSortHeader, Sort} from "@angular/material/sort";
import {FilterOptionWrapperComponent} from '../../../shared/components/filter-option-wrapper/filter-option-wrapper.component';
import {ParameterListComponent} from '../../../shared/components/parameter-list/parameter-list.component';
import {WsModuleResponseDto, WsParameterResponseDto} from '@fertirob/fertirob-api';
import {map, Observable} from 'rxjs';
import {LeavePageOverlayComponent} from '../../../shared/components/leave-page-overlay/leave-page-overlay.component';
import {ParameterListService} from '../../services/parameter-list.service';
import {CustomPaginatorComponent} from '../../../shared/components/custom-paginator/custom-paginator.component';

@Component({
  selector: 'app-module-parameters',
  standalone: true,
  imports: [
    HeadlineComponent,
    ButtonComponent,
    TranslateModule,
    FormsModule,
    InputWrapperComponent,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatOption,
    MatPrefix,
    MatSelect,
    MatSuffix,
    DatePipe,
    EmptyStateListComponent,
    IconButtonComponent,
    MatCheckbox,
    MatSort,
    MatSortHeader,
    FilterOptionWrapperComponent,
    ParameterListComponent,
    AsyncPipe,
    CustomPaginatorComponent
  ],
  templateUrl: './module-parameters.component.html',
  styleUrl: './module-parameters.component.scss'
})
export class ModuleParametersComponent implements OnInit {
  @Input() module?: WsModuleResponseDto;
  @ViewChild(ParameterListComponent) parameterListComponent?: ParameterListComponent;

  public parameters$: Observable<WsParameterResponseDto[] | undefined> = this._parameterListService.getParametersList();
  public filterOptions: string[] = [];

  constructor(private _parameterListService: ParameterListService) {
  }

  get pageSize(): number {
    return this._parameterListService.getPageSize;
  }

  get currentPage(): number {
    return this._parameterListService.getCurrentPage;
  }

  get totalCount(): number {
    return this._parameterListService.getTotalCount;
  }

  ngOnInit(): void {
    this._parameterListService.setModuleId(this.module?.id??'');
    this.getFilterOptions();
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconEnum = IconEnum;

  public getFilterOptions(): void {
    this.filterOptions = ['Alle', 'Einer', 'Beide'];
  }

  sortData($event: Sort) {
    //TODO
  }

  public onSearchTermChange(searchTerm: string): void {
    //TODO call service
  }

  public onFilterOptionChange(filterOption: string): void {
    //TODO call service
  }

  get isEmpty(): Observable<boolean> {
    return this.parameters$.pipe(
      map(parameters => parameters?.length === 0)
    );
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.parameterListComponent ? this.parameterListComponent.canDeactivate() : true;
  }

  public onChangePage(page: number): void {
    this._parameterListService.setCurrentPage(page);
  }
}
