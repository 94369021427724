<app-overlay>
  <ng-container header>
    <app-headline [title]="'documents.trash.delete' | translate">
      <app-icon-button [type]="ButtonType.PLAIN" [iconName]="IconEnum.CLOSE" (click)="cancel()"></app-icon-button>
    </app-headline>
  </ng-container>
  <ng-container content>
    <p>{{ 'documents.trash.deleteText' | translate: {fileName: fileName} }}</p>
  </ng-container>
  <ng-container buttons>
    <app-button [label]="'default.cancel' | translate" [isSmall]="true" [type]="ButtonType.SECONDARY" (click)="cancel()"></app-button>
    <app-button [label]="'documents.trash.finalDelete' | translate" [isSmall]="true" (click)="onSubmit()"></app-button>
  </ng-container>
</app-overlay>
