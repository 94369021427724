import {Component, input, Input} from '@angular/core';
import {IconEnum} from "../../enums/icon-enum";
import {ButtonType} from "../../enums/button-enum";
import {NgStyle} from "@angular/common";

@Component({
  selector: 'app-icon-button',
  standalone: true,
  imports: [
    NgStyle
  ],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss'
})
export class IconButtonComponent {
  @Input() iconName?: IconEnum = IconEnum.ADD;
  @Input() type: ButtonType = ButtonType.PRIMARY
  @Input() label?: string;
  @Input() isDisabled?: boolean;
  @Input() isSmall?: boolean;

  protected readonly ButtonType = ButtonType;
}
