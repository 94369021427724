import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconEnum} from '../../../shared/enums/icon-enum';
import {ButtonType} from '../../../shared/enums/button-enum';
import {DatePipe} from '@angular/common';
import {IconButtonComponent} from '../../../shared/components/icon-button/icon-button.component';
import {RouteConstants} from '../../../shared/constants/route-constants';
import {RouterLink} from '@angular/router';
import {ChipComponent} from '../../../shared/components/chip/chip.component';
import {
  ComponentTableRowInProjectComponent
} from '../component-table-row-in-project/component-table-row-in-project.component';
import {UiCompletedComponent} from '../../classes/ui-completed-component';
import {
  WsComponentResponseDto,
  WsComponentResponseDtoTypeEnum,
  WsModuleResponseDto,
  WsParameterResponseDto,
  WsParameterValueResponseDto
} from '@fertirob/fertirob-api';
import {ChipType} from "../../../shared/enums/chip-enum";

@Component({
  selector: 'app-module-table-row-in-project',
  standalone: true,
  imports: [
    DatePipe,
    IconButtonComponent,
    RouterLink,
    ChipComponent,
    ComponentTableRowInProjectComponent
  ],
  templateUrl: './module-table-row-in-project.component.html',
  styleUrl: './module-table-row-in-project.component.scss'
})
export class ModuleTableRowInProjectComponent implements OnInit {
  @Input() module?: WsModuleResponseDto;
  @Input() projectParameters?: WsParameterValueResponseDto[];
  @Output() onInputChange: EventEmitter<boolean> = new EventEmitter<boolean>;

  public isSelected: boolean = false;
  public moduleAssembliesCompletedStates: UiCompletedComponent[] = [];
  public isModuleCompleted: boolean = true;

  protected readonly IconEnum = IconEnum;

  ngOnInit(): void {
    if (this.module?.components) {
      this.checkIfModuleIsCompleted(this.module?.components);
    }
  }

  public checkIfModuleIsCompleted(components: WsComponentResponseDto[]): void {
    components.forEach((component: WsComponentResponseDto) => {
      component.parameters?.forEach((parameter: WsParameterResponseDto) => {
        if (this.projectParameters) {
          if (this.projectParameters?.filter(projectParameter => projectParameter.parameterId === parameter.id).length === 0) {
            this.isModuleCompleted = false;
            return;
          }
        }
        if (component.subComponents) {
          this.checkIfModuleIsCompleted(component.subComponents);
        }
      })
    })
  }

  public selectModule(): void {
    this.isSelected = !this.isSelected;
  }

  public changeModuleIsCompleted(completedComponent: UiCompletedComponent): void {
    let isAlreadyInArray = this.moduleAssembliesCompletedStates.filter((component) => component.id === completedComponent.id)
    if (isAlreadyInArray.length === 1) {
      this.moduleAssembliesCompletedStates.forEach((component) => {
        if (component.id === completedComponent.id) {
          component.isCompleted = completedComponent.isCompleted;
        }
      })
    } else {
      this.moduleAssembliesCompletedStates.push(completedComponent);
    }
    this.isModuleCompleted = this.moduleAssembliesCompletedStates.every((component) => component.isCompleted);
  }

  public onFormDirty(event: boolean): void {
    this.onInputChange.emit(event);
  }

  public hasParameters(component: WsComponentResponseDto): boolean {
    if (!component?.parameters) {
      return false;
    } else {
      return component?.parameters?.length > 0
    }
  }

  public isAssembly(type?: WsComponentResponseDtoTypeEnum): boolean {
    return type === WsComponentResponseDtoTypeEnum.ASSEMBLY;
  }

  protected readonly ButtonType = ButtonType;
  protected readonly RouteConstants = RouteConstants;
  protected readonly ChipType = ChipType;
}
