@if (isEmpty) {
  <app-empty-state-list [emptyStateText]="'projects.moduleEmptyState' | translate"></app-empty-state-list>
} @else {
  <div class="table table-grid">
    @for (module of project?.modules; track module) {
      <app-module-table-row-in-project [module]="module" [projectParameters]="project?.parameterValues" (onInputChange)="onInputChange($event)" (onParameterValueChange)="onParameterValueChange($event)"></app-module-table-row-in-project>
    }
  </div>
  <div class="button-wrapper bottom-of-page">
    <app-button (click)="onExportProject()" [label]="'default.export' | translate" [type]="ButtonType.SECONDARY"></app-button>
    <app-button (click)="onSaveProjectInputs()" [isDisabled]="!inputChange" [label]="'default.save' | translate" [type]="ButtonType.PRIMARY"></app-button>
  </div>
}

